import { useCallback, useState } from 'react';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import { Link, Stack, TextField, Tooltip, Typography } from '@mui/material';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';

import { fDateTime } from 'src/utils/format-time';
import { hasPermission } from 'src/utils/has-permissions';

import { useAuthContext } from 'src/auth/hooks';
import { AuthPermissions } from 'src/auth/permissions';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

import { IKitchenDispatchOrderListItem } from 'src/types/kitchen-dispatches';

type Props = {
  selected: boolean;
  onEditRow: VoidFunction;
  row: IKitchenDispatchOrderListItem;
  onDeleteRow: VoidFunction;
  isDraft: boolean;
};

export default function KitchenDispatchTableRow({
  row,
  selected,
  onEditRow,
  onDeleteRow,
  isDraft,
}: Props) {
  const { _id, transactionCode, updatedAt, purchaseOrDispatchDate, transactionType } = row;

  const { user: authUser } = useAuthContext();

  const draftConfirm = useBoolean();
  const completedConfirm = useBoolean();

  const popover = usePopover();

  const [verification, setVerification] = useState<string>('');

  const hasRequiredPermissions = useCallback(
    () =>
      (isDraft &&
        hasPermission(
          authUser,
          'any',
          AuthPermissions.CREATE_KITCHEN_INVENTORY_TRANSACTION,
          // AuthPermissions.UPDATE_KITCHEN_INVENTORY_TRANSACTION,
          AuthPermissions.DELETE_KITCHEN_INVENTORY_TRANSACTION,
          AuthPermissions.ADMIN_PERMISSION,
          AuthPermissions.SUPER_ADMIN_PERMISSION
        )) ||
      (!isDraft &&
        hasPermission(
          authUser,
          'any',
          AuthPermissions.UPDATE_KITCHEN_INVENTORY_TRANSACTION,
          AuthPermissions.DELETE_KITCHEN_INVENTORY_TRANSACTION,
          AuthPermissions.ADMIN_PERMISSION,
          AuthPermissions.SUPER_ADMIN_PERMISSION
        )),
    [authUser, isDraft]
  );

  return (
    <>
      <Link
        component={RouterLink}
        href={paths.dashboard.kitchen.dispatch.view(_id)}
        sx={{
          textDecoration: 'none',
          color: 'inherit',
          display: 'contents',
        }}
      >
        <TableRow hover selected={selected}>
          {!isDraft && (
            <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
              {transactionCode}
            </TableCell>
          )}

          <TableCell sx={{ textAlign: 'left' }}>
            <Label variant="soft" color="default">
              {transactionType.split('-').join(' ')}
            </Label>
          </TableCell>

          <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
            {fDateTime(purchaseOrDispatchDate)}
          </TableCell>

          <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
            {fDateTime(updatedAt)}
          </TableCell>

          <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
            <Link component={RouterLink} href={paths.dashboard.kitchen.dispatch.view(_id)} mr={2}>
              <Button variant="soft" color="primary">
                View More
              </Button>
            </Link>

            {hasRequiredPermissions() && (
              <Tooltip title="More Options">
                <IconButton
                  color={popover.open ? 'inherit' : 'default'}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    popover.onOpen(e);
                  }}
                >
                  <Iconify icon="eva:more-vertical-fill" />
                </IconButton>
              </Tooltip>
            )}
          </TableCell>
        </TableRow>
      </Link>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        {hasPermission(
          authUser,
          'any',
          isDraft
            ? AuthPermissions.CREATE_KITCHEN_INVENTORY_TRANSACTION
            : AuthPermissions.UPDATE_KITCHEN_INVENTORY_TRANSACTION,
          AuthPermissions.ADMIN_PERMISSION,
          AuthPermissions.SUPER_ADMIN_PERMISSION
        ) && (
          <MenuItem
            onClick={() => {
              onEditRow();
              popover.onClose();
            }}
          >
            <Iconify icon="solar:pen-bold" />
            Edit
          </MenuItem>
        )}

        {isDraft &&
          hasPermission(
            authUser,
            'any',
            AuthPermissions.CREATE_KITCHEN_INVENTORY_TRANSACTION,
            AuthPermissions.DELETE_KITCHEN_INVENTORY_TRANSACTION,
            AuthPermissions.ADMIN_PERMISSION,
            AuthPermissions.SUPER_ADMIN_PERMISSION
          ) && (
            <MenuItem
              onClick={() => {
                draftConfirm.onTrue();
                popover.onClose();
              }}
              sx={{ color: 'error.main' }}
            >
              <Iconify icon="solar:trash-bin-trash-bold" />
              Delete
            </MenuItem>
          )}

        {!isDraft &&
          hasPermission(
            authUser,
            'any',
            AuthPermissions.DELETE_KITCHEN_INVENTORY_TRANSACTION,
            AuthPermissions.ADMIN_PERMISSION,
            AuthPermissions.SUPER_ADMIN_PERMISSION
          ) && (
            <MenuItem
              onClick={() => {
                completedConfirm.onTrue();
                popover.onClose();
              }}
              sx={{ color: 'error.main' }}
            >
              <Iconify icon="solar:trash-bin-trash-bold" />
              Delete
            </MenuItem>
          )}
      </CustomPopover>

      <ConfirmDialog
        open={draftConfirm.value}
        onClose={draftConfirm.onFalse}
        title="Delete Kitchen Dispatch Order"
        content={`Are you sure want to delete ${
          isDraft ? 'draft' : transactionCode
        }? This action will permenantly delete ${
          isDraft ? 'draft' : transactionCode
        }'s details from the system`}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              draftConfirm.onFalse();
              onDeleteRow();
            }}
          >
            Delete
          </Button>
        }
      />

      <ConfirmDialog
        maxWidth="sm"
        open={completedConfirm.value}
        onClose={completedConfirm.onFalse}
        title="Delete Kitchen Dispatch Order"
        content={
          <Stack>
            <Typography variant="body1">
              You are about to delete a completed dispatch order. This action cannot be undone. To
              proceed, please type the Dispatch Order ID <strong>{transactionCode}</strong> in the
              field below to confirm deletion.
            </Typography>

            <TextField
              sx={{ mt: 2 }}
              placeholder="Enter dispatch order ID"
              value={verification}
              onChange={(e) => setVerification(e.target.value)}
              required
              size="small"
            />
          </Stack>
        }
        action={
          <Button
            variant="contained"
            color="error"
            disabled={verification !== transactionCode}
            onClick={() => {
              completedConfirm.onFalse();
              onDeleteRow();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}
