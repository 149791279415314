import { useEffect, useState } from 'react';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import {
  alpha,
  Button,
  Divider,
  Link,
  ListItemText,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
} from '@mui/material';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { fDateTime } from 'src/utils/format-time';
import { hasPermission } from 'src/utils/has-permissions';
import { TABLE_HEAD_CELL_TYPE, TRANSACTION_TYPE } from 'src/utils/common-types';

import { useAuthContext } from 'src/auth/hooks';
import { AuthPermissions } from 'src/auth/permissions';
import { useAppDispatcher, useAppSelector } from 'src/redux/store';
import { getKitchenTransactionsById } from 'src/redux/slices/kitchen';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import DatePoint from 'src/components/data-point/data-point';
import TableErrorData from 'src/components/table/table-error-data';
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSkeleton,
  useTable,
} from 'src/components/table';

import { IKitchenHistory, IKitchenItem, KitchenStatus } from 'src/types/kitchen';

const LINE_ITEM_HEADERS: TABLE_HEAD_CELL_TYPE[] = [
  {
    id: 'purchaseOrDispatchDate',
    label: 'Transaction Date',
    align: 'left',
    primary: true,
  },
  { id: 'type', label: 'Transaction Type', align: 'left', primary: true },
  { id: 'quantity', label: 'Quantity', align: 'left', primary: true },

  { id: 'updatedAt', label: 'Updated At', primary: true, align: 'left' },
  { id: 'transactionNo', label: 'Transaction #', align: 'left', primary: true },
];

const SUPPLIERS_LINE_ITEM_HEADERS: TABLE_HEAD_CELL_TYPE[] = [
  { id: 'supplierNo', label: '#', align: 'left', primary: true },
  { id: 'supplierName', label: 'Company Name', align: 'left', primary: true },
  {
    id: 'contactPerson',
    label: 'Contact Person',
    align: 'left',
    primary: true,
  },
  {
    id: 'phoneNumber',
    label: 'Phone Number',
    align: 'left',
    primary: true,
  },
];

const TABS = [
  { value: 'overview', label: 'Overview' },
  { value: 'transactions', label: 'Transactions History' },
];

type Props = {
  currentKitchenItem?: IKitchenItem;
};

const defaultSortBy: { sortBy: string; order: 'asc' | 'desc' } = {
  sortBy: 'purchaseOrDispatchDate',
  order: 'desc',
};

export default function KitchenItemDetails({ currentKitchenItem }: Props) {
  const [tabValue, setTabValue] = useState('overview');

  const { user: authUser } = useAuthContext();

  const dispatch = useAppDispatcher();

  const table = useTable({
    defaultRowsPerPage: 25,
  });

  const { page, rowsPerPage } = table;

  const [sort, setSort] = useState(defaultSortBy);

  const {
    transactions: { status, transactions, error },
  } = useAppSelector((state) => state.inventory);

  useEffect(() => {
    if (tabValue === 'transactions') {
      dispatch(
        getKitchenTransactionsById({
          itemID: currentKitchenItem?._id ?? '',
          page,
          limit: rowsPerPage,
          sortBy: sort.sortBy,
          sort: sort.order,
        })
      );
    }
  }, [tabValue, currentKitchenItem, dispatch, page, rowsPerPage, sort]);

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Card sx={{ p: 3 }}>
          <Tabs
            value={tabValue}
            onChange={(_, value) => setTabValue(value)}
            sx={{
              px: 2.5,
              boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {TABS.map((tab) => (
              <Tab key={tab.value} iconPosition="end" value={tab.value} label={tab.label} />
            ))}
          </Tabs>

          {tabValue === 'overview' && (
            <Grid container spacing={2} mt={2}>
              <Grid
                mb={1}
                display="flex"
                mt={2}
                alignItems="center"
                justifyContent="space-between"
                width={1}
              >
                <Typography variant="subtitle1">Basic Information</Typography>

                {hasPermission(
                  authUser,
                  'any',
                  AuthPermissions.UPDATE_KITCHEN_ITEM,
                  AuthPermissions.ADMIN_PERMISSION,
                  AuthPermissions.SUPER_ADMIN_PERMISSION
                ) && (
                  <Link
                    component={RouterLink}
                    href={paths.dashboard.kitchen.edit(currentKitchenItem?._id ?? '')}
                  >
                    <Button variant="text" startIcon={<Iconify icon="solar:pen-bold" />}>
                      Edit
                    </Button>
                  </Link>
                )}
              </Grid>

              <Grid xs={12} md={3}>
                <DatePoint
                  primary="Item Name"
                  secondary={`${currentKitchenItem?.itemName} (${currentKitchenItem?.itemCode})`}
                />
              </Grid>

              <Grid xs={12} md={3}>
                <ListItemText
                  primary="Unit"
                  secondary={currentKitchenItem?.unit.name}
                  primaryTypographyProps={{ typography: 'body2' }}
                  secondaryTypographyProps={{
                    component: 'span',
                    color: 'text.disabled',
                  }}
                />
              </Grid>

              <Grid xs={12} md={3}>
                <DatePoint
                  primary="Re-order threshold"
                  secondary={`${currentKitchenItem?.reorderThreshold} ${currentKitchenItem?.unit.abbreviation}`}
                />
              </Grid>

              <Grid xs={12} md={3}>
                <ListItemText
                  primary="Alerts"
                  secondary={
                    <Label
                      variant="soft"
                      color={currentKitchenItem?.alertEnabled ? 'success' : 'warning'}
                    >
                      {currentKitchenItem?.alertEnabled ? 'Enabled' : 'Disabled'}
                    </Label>
                  }
                  primaryTypographyProps={{ typography: 'body2' }}
                  secondaryTypographyProps={{
                    component: 'span',
                    color: 'text.disabled',
                  }}
                />
              </Grid>

              <Divider
                sx={{
                  my: 2.5,
                  typography: 'overline',
                  color: 'text.disabled',
                  '&:before, :after': {
                    borderTopStyle: 'dashed',
                  },
                }}
              />

              <Grid mb={1} xs={12} width="full">
                <Typography mt={2} variant="subtitle1">
                  Operational Information
                </Typography>
              </Grid>

              <Grid xs={12} md={3}>
                <DatePoint
                  primary="Current Stock"
                  secondary={`${currentKitchenItem?.quantity} ${currentKitchenItem?.unit.abbreviation}`}
                />
              </Grid>

              <Grid xs={12} md={3}>
                <ListItemText
                  primary="Status"
                  secondary={
                    <Label
                      variant="soft"
                      color={
                        (currentKitchenItem?.availability === KitchenStatus.IN_STOCK &&
                          'success') ||
                        (currentKitchenItem?.availability === KitchenStatus.OUT_OF_STOCK &&
                          'error') ||
                        (currentKitchenItem?.availability === KitchenStatus.RUNNING_LOW &&
                          'warning') ||
                        'default'
                      }
                    >
                      {currentKitchenItem?.availability.split('-').join(' ')}
                    </Label>
                  }
                  primaryTypographyProps={{ typography: 'body2' }}
                  secondaryTypographyProps={{
                    component: 'span',
                    color: 'text.disabled',
                  }}
                />
              </Grid>

              <Grid xs={12} md={3}>
                <DatePoint
                  isLink
                  primary="Last Updated"
                  linkText={`${currentKitchenItem?.updatedBy.firstName} ${currentKitchenItem?.updatedBy.lastName}`}
                  secondary={
                    <Link
                      component={RouterLink}
                      href={paths.dashboard.user.view(currentKitchenItem?.updatedBy._id ?? '')}
                    >
                      {`${currentKitchenItem?.updatedBy.firstName} ${currentKitchenItem?.updatedBy.lastName}`}
                    </Link>
                  }
                />
              </Grid>

              {currentKitchenItem?.suppliers && currentKitchenItem?.suppliers.length > 0 && (
                <>
                  <Grid mb={1} xs={12} width="full">
                    <Typography mt={2} variant="subtitle1">
                      Suppliers Information
                    </Typography>
                  </Grid>

                  <Grid xs={12}>
                    <TableContainer
                      sx={{ position: 'relative', overflow: 'hidden', height: '200px', mt: 2 }}
                    >
                      <Scrollbar>
                        <Table stickyHeader size="small" sx={{ minWidth: 960 }}>
                          <TableHeadCustom headLabel={SUPPLIERS_LINE_ITEM_HEADERS} />

                          <TableBody>
                            {currentKitchenItem?.suppliers.map((supplier, index) => (
                              <TableRow hover key={supplier._id}>
                                <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                                  {index + 1 < 10 ? `0${index + 1}` : index}
                                </TableCell>

                                <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                                  <Link
                                    component={RouterLink}
                                    href={paths.dashboard.suppliers.view(supplier._id)}
                                  >
                                    {supplier?.supplierName}
                                  </Link>
                                </TableCell>

                                <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                                  {supplier?.contactPerson}
                                </TableCell>

                                <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                                  {supplier?.phoneNumber}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Scrollbar>
                    </TableContainer>
                  </Grid>
                </>
              )}
            </Grid>
          )}

          {tabValue === 'transactions' && (
            <Stack>
              <Typography variant="subtitle1" sx={{ mt: 2 }}>
                {currentKitchenItem?.itemName}
              </Typography>
              <TableContainer
                sx={{ position: 'relative', overflow: 'hidden', height: '400px', mt: 2 }}
              >
                <Scrollbar>
                  <Table stickyHeader size="medium" sx={{ minWidth: 960 }}>
                    <TableHeadCustom
                      headLabel={LINE_ITEM_HEADERS}
                      order={sort.order}
                      orderBy={sort.sortBy}
                      rowCount={transactions.items.length}
                      onSort={(id) => {
                        if (id === sort.sortBy) {
                          setSort((prev) => ({
                            ...prev,
                            order: prev.order === 'asc' ? 'desc' : 'asc',
                          }));
                        } else {
                          setSort({
                            sortBy: id,
                            order: 'asc',
                          });
                        }
                      }}
                    />

                    <TableBody>
                      {status === 'loading' ? (
                        [...Array(10)].map((i, index) => <TableSkeleton key={index} />)
                      ) : (
                        <>
                          {transactions?.items.map((row: IKitchenHistory, index: number) => (
                            <TableRow hover key={row.transactionDetails._id}>
                              <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                                {fDateTime(row.transactionDetails.purchaseOrDispatchDate)}
                              </TableCell>

                              <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                                <Label
                                  variant="soft"
                                  color={
                                    (row.transactionType === TRANSACTION_TYPE.DISPATCH &&
                                      'success') ||
                                    (row.transactionType === TRANSACTION_TYPE.PURCHASE &&
                                      'error') ||
                                    (row.transactionType === TRANSACTION_TYPE.DISPOSAL &&
                                      'warning') ||
                                    'default'
                                  }
                                >
                                  {row.transactionType.split('-').join(' ')}
                                </Label>
                              </TableCell>

                              <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                                {row.quantity}
                              </TableCell>

                              <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                                {fDateTime(row.updatedAt)}
                              </TableCell>

                              <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                                <Link
                                  component={RouterLink}
                                  href={
                                    row.transactionType === TRANSACTION_TYPE.PURCHASE
                                      ? paths.dashboard.kitchen.purchase.view(
                                          row.transactionDetails._id
                                        )
                                      : paths.dashboard.kitchen.dispatch.view(
                                          row.transactionDetails._id
                                        )
                                  }
                                >
                                  {row.transactionDetails.transactionCode}
                                </Link>
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}

                      {status !== 'failure' && transactions.count === 0 && (
                        <TableNoData
                          message="No transactions found"
                          notFound={transactions.count === 0}
                        />
                      )}

                      {status === 'failure' && <TableErrorData notFound error={error} />}
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
              <TablePaginationCustom
                count={transactions.count}
                page={table.page}
                rowsPerPage={table.rowsPerPage}
                onPageChange={table.onChangePage}
                onRowsPerPageChange={table.onChangeRowsPerPage}
                dense={table.dense}
                onChangeDense={table.onChangeDense}
              />
            </Stack>
          )}

          <Stack direction="row" justifyContent="flex-end" gap={2} mt={2}>
            <Link
              component={RouterLink}
              href={paths.dashboard.kitchen.root()}
              sx={{
                width: {
                  xs: 1,
                  sm: 'auto',
                },
              }}
            >
              <Button
                variant="outlined"
                color="inherit"
                sx={{
                  width: 1,
                }}
              >
                Cancel
              </Button>
            </Link>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
}
