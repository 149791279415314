import { useState } from 'react';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Button,
  Link,
  ListItemText,
  Tab,
  Tabs,
} from '@mui/material';

import { RouterLink } from 'src/routes/components';

import { hasPermission } from 'src/utils/has-permissions';

import { IUser } from 'src/redux/slices/users';
import { useAuthContext } from 'src/auth/hooks';
import { AccountStatus } from 'src/config-global';
import { AuthPermissions } from 'src/auth/permissions';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import DatePoint from 'src/components/data-point/data-point';

import { paths } from '../../routes/paths';

const TABS = [
  { value: 'overview', label: 'Overview' },
  { value: 'permissions', label: 'Permissions' },
];

type Props = {
  currentUser?: IUser;
};

export default function UserDetails({ currentUser }: Props) {
  const [tabValue, setTabValue] = useState('overview');

  const { user: authUser } = useAuthContext();

  const renderGroupHeader = (group: any) => {
    const count = group.permissions.filter((permission: any) => permission.checked).length;

    return (
      <>
        <Typography variant="body2" mr={1}>
          {group.name}
        </Typography>
        {count > 0 && (
          <Label color="success">
            {count < 10
              ? `0${count} Permission${count > 1 ? 's' : ''}`
              : `${count} Permission ${count > 1 ? 's' : ''}`}
          </Label>
        )}
      </>
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Card sx={{ p: 3 }}>
          <Tabs
            value={tabValue}
            onChange={(_, value) => setTabValue(value)}
            sx={{
              px: 2.5,
              boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {TABS.map((tab) => (
              <Tab key={tab.value} iconPosition="end" value={tab.value} label={tab.label} />
            ))}
          </Tabs>

          {tabValue === 'overview' && (
            <Grid container spacing={2} mt={2}>
              <Grid
                mb={1}
                display="flex"
                mt={2}
                alignItems="center"
                justifyContent="space-between"
                width={1}
              >
                <Typography variant="subtitle1">Basic Information</Typography>

                {hasPermission(
                  authUser,
                  'any',
                  AuthPermissions.UPDATE_USER,
                  AuthPermissions.ADMIN_PERMISSION,
                  AuthPermissions.SUPER_ADMIN_PERMISSION
                ) && (
                  <Link
                    component={RouterLink}
                    href={paths.dashboard.user.edit(currentUser?._id ?? '')}
                  >
                    <Button variant="text" startIcon={<Iconify icon="solar:pen-bold" />}>
                      Edit
                    </Button>
                  </Link>
                )}
              </Grid>

              <Grid xs={12} md={4}>
                <DatePoint
                  primary="Full Name"
                  secondary={`${currentUser?.firstName} ${currentUser?.lastName}`}
                />
              </Grid>

              <Grid xs={12} md={4}>
                <DatePoint primary="Job Role" secondary={currentUser?.jobRole} />
              </Grid>

              <Grid xs={12} md={4}>
                <ListItemText
                  primary="User Status"
                  secondary={
                    <Label
                      variant="soft"
                      color={
                        (currentUser?.status === AccountStatus.ACTIVE && 'success') ||
                        (currentUser?.status === AccountStatus.BLOCKED && 'error') ||
                        'default'
                      }
                    >
                      {currentUser?.status === AccountStatus.ACTIVE ? 'Active' : 'Blocked'}
                    </Label>
                  }
                  primaryTypographyProps={{ typography: 'body2' }}
                  secondaryTypographyProps={{
                    component: 'span',
                    color: 'text.disabled',
                  }}
                />
              </Grid>

              <Grid xs={12} md={4}>
                <DatePoint primary="Email Address" secondary={currentUser?.email} />
              </Grid>
            </Grid>
          )}

          {tabValue === 'permissions' && (
            <Stack
              gap={2}
              mt={2}
              mb={2}
              sx={{
                height: '400px',
                overflowY: 'auto',
                pr: 2,
              }}
            >
              <Typography variant="subtitle1" sx={{ mt: 2 }}>
                {currentUser?.firstName} {currentUser?.lastName}
              </Typography>

              {currentUser?.permissions.map((group, groupIndex) => {
                if (group) {
                  return (
                    <Accordion key={group.name}>
                      <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}>
                        {renderGroupHeader(group)}
                      </AccordionSummary>

                      <AccordionDetails>
                        {group.permissions.map((permission) => (
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            key={permission._id}
                            mb={2}
                          >
                            <ListItemText
                              primary={permission.name.toLocaleLowerCase().split('_').join(' ')}
                              secondary={permission.description}
                              primaryTypographyProps={{
                                typography: 'body2',
                                textTransform: 'capitalize',
                              }}
                              secondaryTypographyProps={{
                                component: 'span',
                                color: 'text.disabled',
                              }}
                            />
                          </Stack>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  );
                }

                return <span key={groupIndex} />;
              })}
            </Stack>
          )}

          <Stack direction="row" justifyContent="flex-end" gap={2} mt={2}>
            <Link
              component={RouterLink}
              href={paths.dashboard.user.root()}
              sx={{
                width: {
                  xs: 1,
                  sm: 'auto',
                },
              }}
            >
              <Button
                variant="outlined"
                color="inherit"
                sx={{
                  width: 1,
                }}
              >
                Cancel
              </Button>
            </Link>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
}
