import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  InputAdornment,
  Link,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TextField,
} from '@mui/material';

import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';

import { fCurrencyRupees } from 'src/utils/format-number';
import {
  TABLE_HEAD_CELL_TYPE,
  TRANSACTION_PAYMENT_STATUS,
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
} from 'src/utils/common-types';

import { useAuthContext } from 'src/auth/hooks';
import { KITCHEN_CATEGORY_ID } from 'src/config-global';
import { getInventory } from 'src/redux/slices/kitchen';
import { getPaginatedUnits } from 'src/redux/slices/units';
import { useAppDispatcher, useAppSelector } from 'src/redux/store';
import { getPaginatedSuppliers } from 'src/redux/slices/suppliers';
import {
  createKitchenPurchaseOrder,
  TCreateKitchenPurchaseOrder,
  TCreateKitchenPurchaseOrderItem,
  updateKitchenPurchaseCompletedOrder,
  updateKitchenPurchaseOrder,
} from 'src/redux/slices/kitchen-purchase-order';

import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom } from 'src/components/table';
import { ConfirmDialog } from 'src/components/custom-dialog';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
import CustomDatePicker from 'src/components/custom-datepicker/custom-datepicker';

import { DocumentStatus } from 'src/types/common';
import { ISupplierListItem } from 'src/types/supplier';
import {
  IKitchenPurchaseOrderDetailedItem,
  KitchenPurchaseOrdersStatus,
} from 'src/types/kitchen-purchase';

import { paths } from '../../routes/paths';
import KitchenPurchaseNewItemRow from './kitchen-purchase-new-item-row';

const LINE_ITEM_HEADERS: TABLE_HEAD_CELL_TYPE[] = [
  { id: 'itemNo', label: '#', align: 'left', primary: true },
  {
    id: 'itemName',
    label: 'Item Name',
    align: 'left',
    primary: true,
    minWidth: 300,
  },
  { id: 'quantity', label: 'Quantity', align: 'left', primary: true },
  { id: 'unit', label: 'Unit', align: 'left', primary: true, minWidth: 100 },
  {
    id: 'unitPrice',
    label: 'Unit Price',
    align: 'right',
    primary: true,
  },
  { id: 'discount', label: 'Discount', align: 'right', primary: true },
  { id: 'total', label: 'Total', align: 'right', primary: true },
  { id: '', primary: true },
];

export type ConditionalSchema<T> = T extends string
  ? Yup.StringSchema
  : T extends number
  ? Yup.NumberSchema
  : T extends boolean
  ? Yup.BooleanSchema
  : T extends Record<any, any>
  ? Yup.AnyObjectSchema
  : T extends Array<any>
  ? Yup.ArraySchema<any, any>
  : Yup.AnySchema;

export type Shape<Fields> = {
  [Key in keyof Fields]: ConditionalSchema<Fields[Key]>;
};

type Props = {
  currentKitchenPurchaseItem?: IKitchenPurchaseOrderDetailedItem;
};

export default function KitchenPurchaseNewEditForm({ currentKitchenPurchaseItem }: Props) {
  const dispatch = useAppDispatcher();

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const { user: authUser } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const confirm = useBoolean();

  const { suppliers } = useAppSelector((state) => state.suppliers);

  const [selectedSupplier, setSelectedSupplier] = useState<ISupplierListItem | null>(null);
  const [localSuppliers, setLocalSuppliers] = useState<ISupplierListItem[]>([]);

  const [changingSupplier, setChangingSupplier] = useState<ISupplierListItem | null>(null);
  const [subTotal, setSubTotal] = useState<number>(0);

  const [removingLineItems, setRemovingLineItems] = useState<TCreateKitchenPurchaseOrderItem[]>([]);

  const NewKitchenPurchaseOrderSchema = Yup.object<Shape<TCreateKitchenPurchaseOrder>>().shape({
    category: Yup.string().required('Category is required'),
    supplier: Yup.string().optional().nullable(),
    reason: Yup.string().nullable(),
    description: Yup.string().nullable(),
    discount: Yup.number()
      .typeError('')
      .min(0, 'Discount cannot be negative')
      .required('Discount is required'),
    isPercentageDiscount: Yup.boolean().required('Discount type is required'),
    totalAfterDiscount: Yup.number()
      .typeError('')
      .min(0, 'Total price cannot be negative')
      .required('Total price is required'),
    transactionType: Yup.mixed<TRANSACTION_TYPE>()
      .oneOf(Object.values(TRANSACTION_TYPE))
      .required('Transaction type is required'),
    paymentStatus: Yup.mixed<TRANSACTION_PAYMENT_STATUS>()
      .oneOf(Object.values(TRANSACTION_PAYMENT_STATUS))
      .required('Payment status is required'),
    purchaseOrDispatchDate: Yup.date()
      .typeError('Invalid purchase date')
      .required('Purchase date is required'),
    status: Yup.mixed<TRANSACTION_STATUS>()
      .oneOf(Object.values(TRANSACTION_STATUS))
      .required('Status is required'),
    items: Yup.array()
      .of(
        Yup.object<Shape<TCreateKitchenPurchaseOrderItem>>().shape({
          inventoryItem: Yup.string().required('Kitchen item is required'),
          quantity: Yup.number()
            .typeError('')
            .moreThan(0, 'Quantity cannot be negative')
            .required('Quantity is required'),
          unit: Yup.string().required('Unit is required'),
          unitPrice: Yup.number()
            .typeError('')
            .moreThan(0, 'Unit price cannot be negative')
            .required('Unit price is required'),
          discount: Yup.number()
            .typeError('')
            .min(0, 'Discount cannot be negative')
            .required('Discount is required'),
          totalAfterDiscount: Yup.number()
            .typeError('')
            .min(0, 'Total price cannot be negative')
            .required('Total price is required'),
        })
      )
      .min(1, 'At least one item is required')
      .required('Items are required'),
  });

  const defaultItem = useMemo(
    () => ({
      inventoryItem: '',
      quantity: 0,
      unit: '',
      description: null,
      unitPrice: 0,
      discount: 0,
      isPercentageDiscount: false,
      totalAfterDiscount: 0,
    }),
    []
  );

  const defaultValues = useMemo<TCreateKitchenPurchaseOrder>(
    () => ({
      category: KITCHEN_CATEGORY_ID,
      transactionType: TRANSACTION_TYPE.PURCHASE,
      description: currentKitchenPurchaseItem?.description ?? null,
      reason: currentKitchenPurchaseItem?.reason ?? null,
      discount: currentKitchenPurchaseItem?.discount ?? 0,
      isPercentageDiscount: currentKitchenPurchaseItem?.isPercentageDiscount ?? false,
      paymentStatus: currentKitchenPurchaseItem?.paymentStatus ?? TRANSACTION_PAYMENT_STATUS.PAID,
      totalAfterDiscount: currentKitchenPurchaseItem?.totalAfterDiscount ?? 0,
      status: currentKitchenPurchaseItem?.status ?? TRANSACTION_STATUS.DRAFT,
      supplier: currentKitchenPurchaseItem?.supplier?._id ?? null,
      items:
        currentKitchenPurchaseItem?.items.map((item) => ({
          ...item,
          inventoryItem: item.inventoryItem._id,
          unit: item.unit._id,
        })) ?? [],
      purchaseOrDispatchDate: currentKitchenPurchaseItem?.purchaseOrDispatchDate
        ? new Date(currentKitchenPurchaseItem.purchaseOrDispatchDate)
        : new Date(),
    }),
    [currentKitchenPurchaseItem]
  );

  const methods = useForm({
    resolver: yupResolver(NewKitchenPurchaseOrderSchema),
    defaultValues,
    mode: 'onChange',
  });

  const {
    reset,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  const watchItems = useWatch({
    control,
    name: 'items',
  });

  const watchDiscount = useWatch({
    control,
    name: 'discount',
  });

  const handleSubmitWithStatus = (status: TRANSACTION_STATUS) =>
    handleSubmit(async (data) => {
      const formData: TCreateKitchenPurchaseOrder = {
        ...data,
        status,
        supplier: data.supplier ?? null,
        reason: data.reason ?? `${authUser?.firstName} created PO`,
        description: data.description ?? null,
        items:
          currentKitchenPurchaseItem?.status === TRANSACTION_STATUS.COMPLETED
            ? [
                ...data.items.map((item) => ({
                  ...item,
                  description: null,
                })),
                ...removingLineItems.map((item) => ({
                  ...item,
                  quantity: 0,
                  unitPrice: 0,
                  discount: 0,
                  totalAfterDiscount: 0,
                })),
              ]
            : data.items.map((item) => ({
                ...item,
                description: null,
              })),
      };

      if (currentKitchenPurchaseItem) {
        try {
          if (
            currentKitchenPurchaseItem.status === TRANSACTION_STATUS.DRAFT &&
            status === TRANSACTION_STATUS.COMPLETED
          ) {
            await dispatch(
              updateKitchenPurchaseOrder({
                purchaseOrderId: currentKitchenPurchaseItem._id,
                poTransaction: formData,
              })
            ).unwrap();
          } else if (status === TRANSACTION_STATUS.COMPLETED) {
            await dispatch(
              updateKitchenPurchaseCompletedOrder({
                purchaseOrderId: currentKitchenPurchaseItem._id,
                poTransaction: formData,
              })
            ).unwrap();
          } else {
            await dispatch(
              updateKitchenPurchaseOrder({
                purchaseOrderId: currentKitchenPurchaseItem._id,
                poTransaction: formData,
              })
            ).unwrap();
          }

          enqueueSnackbar('Kithen PO update success');

          if (status === TRANSACTION_STATUS.COMPLETED) {
            navigate(paths.dashboard.kitchen.purchase.root());
          } else {
            navigate(paths.dashboard.kitchen.purchase.root(KitchenPurchaseOrdersStatus.DRAFT));
          }

          reset();
        } catch (e) {
          if (typeof e === 'string') {
            enqueueSnackbar(e, {
              variant: 'error',
            });
          } else {
            enqueueSnackbar(e?.message, {
              variant: 'error',
            });
          }
        }
      } else {
        try {
          await dispatch(
            createKitchenPurchaseOrder({
              poTransaction: formData,
            })
          ).unwrap();

          enqueueSnackbar('Kithen PO creation success');

          if (status === TRANSACTION_STATUS.COMPLETED) {
            navigate(paths.dashboard.kitchen.purchase.root());
          } else {
            navigate(paths.dashboard.kitchen.purchase.root(KitchenPurchaseOrdersStatus.DRAFT));
          }

          reset();
        } catch (e) {
          if (typeof e === 'string') {
            enqueueSnackbar(e, {
              variant: 'error',
            });
          } else {
            enqueueSnackbar(e?.message, {
              variant: 'error',
            });
          }
        }
      }
    })();

  const fetchPaginatedSuppliers = useCallback(
    () =>
      dispatch(
        getPaginatedSuppliers({
          page: null,
          limit: null,
          generalSearch: null,
          sortBy: 'supplierName',
          sort: 'asc',
        })
      ),
    [dispatch]
  );

  const fetchPaginatedKitchenItems = useCallback(() => {
    dispatch(getPaginatedUnits({}));

    dispatch(
      getInventory({
        sortBy: 'itemName',
        sort: 'asc',
        status: currentKitchenPurchaseItem ? null : DocumentStatus.ACTIVE,
        suppliers: selectedSupplier ? [selectedSupplier._id] : [],
      })
    );
  }, [dispatch, currentKitchenPurchaseItem, selectedSupplier]);

  const scrollToBottom = () => {
    if (tableContainerRef.current) {
      const scrollContainer = tableContainerRef.current.querySelector('.simplebar-content-wrapper');
      if (scrollContainer) {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }
    }
  };

  const handleAppendItem = () => {
    if (fields.length === 0) {
      fetchPaginatedKitchenItems();
    }

    append(defaultItem);
    // Use setTimeout to ensure DOM has updated before scrolling
    setTimeout(scrollToBottom, 100);
  };

  useEffect(() => {
    fetchPaginatedSuppliers();
  }, [fetchPaginatedSuppliers]);

  useEffect(() => {
    setLocalSuppliers(suppliers.suppliers);

    setSelectedSupplier(currentKitchenPurchaseItem?.supplier ?? null);
  }, [suppliers, currentKitchenPurchaseItem]);

  useEffect(() => {
    if (currentKitchenPurchaseItem) {
      fetchPaginatedKitchenItems();
    }
  }, [currentKitchenPurchaseItem, fetchPaginatedKitchenItems]);

  useEffect(() => {
    let calculatedSubTotal = 0;

    watchItems.forEach((item, index) => {
      const calculatedTotal = item.unitPrice * item.quantity - item.discount;

      calculatedSubTotal += calculatedTotal;

      if (calculatedTotal !== item.totalAfterDiscount) {
        setValue(`items.${index}.totalAfterDiscount`, calculatedTotal);
      }
    });

    setSubTotal(calculatedSubTotal);

    setValue('totalAfterDiscount', calculatedSubTotal - watchDiscount);
  }, [watchItems, setValue, watchDiscount]);

  useEffect(() => {
    setValue('totalAfterDiscount', subTotal - watchDiscount);
  }, [setValue, watchDiscount, subTotal, watch]);

  const renderCreatePurchaseOrderButton = () => {
    if (
      currentKitchenPurchaseItem?.status === TRANSACTION_STATUS.DRAFT ||
      !currentKitchenPurchaseItem
    ) {
      return (
        <LoadingButton
          type="button"
          variant="outlined"
          loading={isSubmitting}
          onClick={() => handleSubmitWithStatus(TRANSACTION_STATUS.COMPLETED)}
        >
          {currentKitchenPurchaseItem?.status === TRANSACTION_STATUS.DRAFT
            ? 'Mark as completed'
            : 'Create Purchase Order'}
        </LoadingButton>
      );
    }
    return null;
  };

  const handleSubmitAction = () => {
    if (!currentKitchenPurchaseItem) {
      handleSubmitWithStatus(TRANSACTION_STATUS.DRAFT);
      return;
    }

    if (currentKitchenPurchaseItem?.status === TRANSACTION_STATUS.DRAFT) {
      handleSubmitWithStatus(TRANSACTION_STATUS.DRAFT);
      return;
    }

    handleSubmitWithStatus(TRANSACTION_STATUS.COMPLETED);
  };

  const getButtonText = () => {
    if (!currentKitchenPurchaseItem) {
      return 'Save as Draft';
    }

    if (currentKitchenPurchaseItem?.status === TRANSACTION_STATUS.DRAFT) {
      return 'Update Draft';
    }

    return 'Save Changes';
  };

  const isCompleted = currentKitchenPurchaseItem?.status === TRANSACTION_STATUS.COMPLETED;

  return (
    <>
      <FormProvider methods={methods}>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <Card sx={{ p: 3 }}>
              <Grid container spacing={2}>
                <Grid
                  mb={1}
                  display="flex"
                  mt={2}
                  alignItems="center"
                  justifyContent="space-between"
                  width={1}
                >
                  <Typography variant="subtitle2">Bill Information</Typography>
                </Grid>

                <Grid xs={12} md={4}>
                  <Controller
                    name="purchaseOrDispatchDate"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <CustomDatePicker
                        disabled={isCompleted}
                        disableFuture
                        label="Purchase Date"
                        value={field.value}
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!error,
                            helperText: error?.message,
                            required: true,
                          },
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={4}>
                  <Autocomplete
                    disabled={isCompleted}
                    options={localSuppliers}
                    getOptionLabel={(option) =>
                      typeof option === 'string' ? option : option.supplierName
                    }
                    autoComplete
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    filterSelectedOptions
                    value={selectedSupplier}
                    onChange={(event: any, newValue: ISupplierListItem | null) => {
                      if (watchItems.length > 0 && watchItems[0].inventoryItem !== '') {
                        setChangingSupplier(newValue);
                        confirm.onTrue();
                      } else {
                        setSelectedSupplier(newValue ?? null);
                        setChangingSupplier(newValue);
                        setValue('supplier', newValue?._id);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Search for supplier..." fullWidth />
                    )}
                    renderOption={(props, option) => (
                      <li key={option._id} {...props}>
                        <Typography
                          variant="body2"
                          sx={{ color: 'text.secondary', textTransform: 'capitalize' }}
                        >
                          {option.supplierName}
                        </Typography>
                      </li>
                    )}
                  />
                </Grid>

                <Grid xs={12} md={4}>
                  <RHFSelect fullWidth name="paymentStatus" label="Payment Status" required>
                    {Object.values(TRANSACTION_PAYMENT_STATUS).map((type) => (
                      <MenuItem
                        key={type}
                        value={type}
                        sx={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {type.split('_').join(' ')}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                </Grid>

                <Divider
                  sx={{
                    width: 1,
                    my: 2,
                    borderStyle: 'dashed',
                  }}
                />

                <Grid mb={1} xs={12} width="full">
                  <Typography variant="subtitle2">Line Items</Typography>
                </Grid>

                <Grid xs={12}>
                  <Stack spacing={2}>
                    <TableContainer
                      ref={tableContainerRef}
                      sx={{ position: 'relative', overflow: 'hidden', height: '250px' }}
                    >
                      <Scrollbar>
                        <Table stickyHeader size="small" sx={{ minWidth: 960 }}>
                          <TableHeadCustom headLabel={LINE_ITEM_HEADERS} />

                          <TableBody>
                            {fields.map((item, index) => (
                              <KitchenPurchaseNewItemRow
                                key={item.id}
                                index={index}
                                remove={(itemIndex) => {
                                  remove(itemIndex);
                                  fetchPaginatedKitchenItems();
                                  if (isCompleted) {
                                    setRemovingLineItems((prev) => [...prev, item]);
                                  }
                                }}
                                isLast={fields.length === 1 && index === 0}
                                isCompleted={isCompleted}
                              />
                            ))}
                          </TableBody>
                        </Table>
                      </Scrollbar>
                    </TableContainer>
                  </Stack>

                  {currentKitchenPurchaseItem?.status !== TRANSACTION_STATUS.COMPLETED && (
                    <Stack mt={4}>
                      <Button variant="soft" color="primary" onClick={handleAppendItem}>
                        Add
                      </Button>
                    </Stack>
                  )}
                </Grid>
              </Grid>

              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent="flex-end"
                alignItems="center"
                gap={2}
                mt={3}
                divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
              >
                <Stack direction="row" gap={2}>
                  <Typography variant="subtitle2">Sub Total</Typography>
                  <Typography variant="subtitle2">{fCurrencyRupees(subTotal * 100)}</Typography>
                </Stack>

                <Stack direction="row" gap={2} alignItems="center">
                  <Typography variant="caption">Discount</Typography>
                  <RHFTextField
                    size="small"
                    name="discount"
                    type="number"
                    placeholder="0.00"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box sx={{ typography: 'subtitle2', color: 'text.disabled' }}>Rs.</Box>
                        </InputAdornment>
                      ),
                    }}
                    sx={{ maxWidth: { md: 150 } }}
                  />
                </Stack>

                <Stack direction="row" gap={2}>
                  <Typography variant="subtitle1">Grand Total</Typography>
                  <Typography variant="subtitle1">
                    {fCurrencyRupees(watch('totalAfterDiscount') * 100)}
                  </Typography>
                </Stack>
              </Stack>

              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent="space-between"
                gap={2}
                mt={5}
              >
                <Link
                  component={RouterLink}
                  href={paths.dashboard.kitchen.purchase.root()}
                  sx={{
                    width: {
                      xs: '100%',
                      sm: 'auto',
                    },
                  }}
                >
                  <Button variant="outlined" color="inherit" sx={{ width: 1 }}>
                    Cancel
                  </Button>
                </Link>

                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="flex-end" gap={2}>
                  {renderCreatePurchaseOrderButton()}

                  <LoadingButton
                    type="button"
                    variant="contained"
                    loading={isSubmitting}
                    onClick={handleSubmitAction}
                  >
                    {getButtonText()}
                  </LoadingButton>
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>

      <ConfirmDialog
        open={confirm.value}
        onClose={() => {
          setChangingSupplier(null);
          confirm.onFalse();
        }}
        title="Changing Supplier?"
        content="This action will permanently reset the purchase order"
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setValue('items', []);
              setValue('supplier', changingSupplier?._id);
              setSelectedSupplier(changingSupplier);
              confirm.onFalse();
            }}
          >
            Reset
          </Button>
        }
      />
    </>
  );
}
