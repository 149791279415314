import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  TRANSACTION_PAYMENT_STATUS,
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
} from 'src/utils/common-types';

import { BAR_CATEGORY_ID } from 'src/config-global';

import { IBarPurchaseOrderDetailedItem, IBarPurchaseOrderListItem } from 'src/types/bar-purchase';

import axios from '../../utils/axios';

interface IInitialState {
  status: 'idle' | 'loading' | 'success' | 'failure';
  error: string | undefined;
  transactions: {
    count: number;
    page: number;
    pageSize: number;
    pageCount: number;
    transactions: IBarPurchaseOrderListItem[];
  };
  item: IBarPurchaseOrderDetailedItem | null;
}

const initialState: IInitialState = {
  status: 'idle',
  error: undefined,
  transactions: {
    count: 0,
    page: 1,
    pageSize: 10,
    pageCount: 0,
    transactions: [],
  },
  item: null,
};

const barPurchaseOrders = createSlice({
  name: 'barPurchaseOrders',
  initialState,
  reducers: {
    removeBarPurchaseOrderItem: (state, action) => {
      const { itemId } = action.payload;

      const index = state.transactions.transactions.findIndex((item) => item._id === itemId);

      if (index > -1) {
        action.payload.index = index; // Store the index for rollback
        state.transactions.transactions = state.transactions.transactions.filter(
          (item) => item._id !== itemId
        );
      }
    },
    rollbackBarPurchaseOrderDeletion: (state, action) => {
      const { item, index } = action.payload;

      state.transactions.transactions.splice(index, 0, item);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBarPurchaseOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getBarPurchaseOrders.fulfilled, (state, action) => {
        state.status = 'success';
        state.transactions = action.payload;
      })
      .addCase(getBarPurchaseOrders.rejected, (state, action) => {
        state.status = 'failure';
        state.error = action.error.message;
      })
      .addCase(getBarPurchaseOrderById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getBarPurchaseOrderById.fulfilled, (state, action) => {
        state.status = 'success';
        state.item = action.payload;
      })
      .addCase(getBarPurchaseOrderById.rejected, (state, action) => {
        state.status = 'failure';
        state.error = action.error.message;
      });
  },
});

export default barPurchaseOrders.reducer;

export const { removeBarPurchaseOrderItem, rollbackBarPurchaseOrderDeletion } =
  barPurchaseOrders.actions;

export const getBarPurchaseOrders = createAsyncThunk(
  'bar/purchase/transactions',
  async (
    params: {
      page: number;
      limit: number;
      generalSearch?: string | null;
      status?: string | null;
      paymentStatus?: string | null;
      purchaseDate?: string | null;
      minTotalValue?: number | null;
      maxTotalValue?: number | null;
      sortBy?: string | null;
      sort?: 'asc' | 'desc' | null;
    } = {
      page: 1,
      limit: 10,
      generalSearch: null,
      status: null,
      paymentStatus: null,
      purchaseDate: null,
      minTotalValue: null,
      maxTotalValue: null,
      sort: 'desc',
      sortBy: 'updatedAt',
    }
  ) => {
    const {
      page,
      limit,
      generalSearch,
      status,
      paymentStatus,
      purchaseDate,
      minTotalValue,
      maxTotalValue,
      sortBy,
      sort,
    } = params;

    try {
      const response = await axios.get(
        `inventory/bar/transaction?page=${page + 1}&limit=${limit}&generalSearch=${
          generalSearch ?? ''
        }&transactionCode=&status=${status ?? ''}&transactionType=purchase&paymentStatus=${
          paymentStatus ?? ''
        }&purchaseOrDispatchDate=${purchaseDate ?? ''}&minTotalValue=${
          minTotalValue ?? ''
        }&maxTotalValue=${
          maxTotalValue ?? ''
        }&isDiscounted=&suppliers=&categories=${BAR_CATEGORY_ID}&updatedBy=&sortBy=${
          sortBy ?? ''
        }&sort=${sort ?? ''}&createdBy=&customers=`
      );

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const getBarPurchaseOrderById = createAsyncThunk(
  'bar/purchase/transaction/id',
  async (params: { itemID: string }) => {
    try {
      const { itemID } = params;
      const response = await axios.get(`inventory/bar/transaction/${itemID}`);

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const deleteBarPurchaseOrder = createAsyncThunk(
  'bar/purchase/delete',
  async (params: { item: IBarPurchaseOrderListItem; index: number }, { dispatch }) => {
    const { item, index } = params;

    try {
      const response = await axios.delete(`inventory/bar/transaction/${item._id}`);
      return response.data.data;
    } catch (err) {
      dispatch(rollbackBarPurchaseOrderDeletion({ item, index }));
      throw err;
    }
  }
);

export interface TCreateBarPurchaseOrderItem {
  inventoryItem: string;
  quantity: number;
  unit: string;
  unitPrice: number;
  discount: number;
  totalAfterDiscount: number;
}

export interface TCreateBarPurchaseOrder {
  category: string;
  supplier: string;
  reason: string | null;
  discount: number;
  isPercentageDiscount: boolean;
  totalAfterDiscount: number;
  transactionType: TRANSACTION_TYPE;
  paymentStatus: TRANSACTION_PAYMENT_STATUS;
  purchaseOrDispatchDate: Date;
  status: TRANSACTION_STATUS;
  items: TCreateBarPurchaseOrderItem[];
}

export const createBarPurchaseOrder = createAsyncThunk(
  'bar/purchaseOrder/create',
  async (params: { poTransaction: TCreateBarPurchaseOrder }) => {
    const { poTransaction } = params;
    const response = await axios.post(`inventory/bar/transaction`, poTransaction);
    return response.data.data;
  }
);

export const updateBarPurchaseOrder = createAsyncThunk(
  'bar/purchaseOrder/update',
  async (params: { purchaseOrderId: string; poTransaction: TCreateBarPurchaseOrder }) => {
    const { poTransaction, purchaseOrderId } = params;
    const response = await axios.put(`inventory/bar/transaction/${purchaseOrderId}`, poTransaction);
    return response.data.data;
  }
);

export const updateCompletedBarPurchaseOrder = createAsyncThunk(
  'bar/purchaseOrder/completed/update',
  async (params: { purchaseOrderId: string; poTransaction: TCreateBarPurchaseOrder }) => {
    const { poTransaction, purchaseOrderId } = params;
    const response = await axios.put(`inventory/transaction/${purchaseOrderId}`, poTransaction);
    return response.data.data;
  }
);

export const barPurchaseOrderMarkAsCompleted = createAsyncThunk(
  'bar/purchaseOrder/marAsCompleted',
  async (params: { purchaseOrderId: string; purchaseOrder: IBarPurchaseOrderDetailedItem }) => {
    const { purchaseOrderId, purchaseOrder } = params;

    const response = await axios.put(`/inventory/bar/transaction/${purchaseOrderId}`, {
      ...purchaseOrder,
      status: TRANSACTION_STATUS.COMPLETED,
      supplier: purchaseOrder.supplier?._id,
      items: purchaseOrder.items.map((item) => ({
        ...item,
        inventoryItem: item.inventoryItem._id,
        unit: item.unit._id,
      })),
    });
    return response.data.data;
  }
);
