import { useEffect } from 'react';

import { Button } from '@mui/material';
import Container from '@mui/material/Container';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useResponsive } from 'src/hooks/use-responsive';

import { getKitchenPurchaseOrderById } from 'src/redux/slices/kitchen-purchase-order';

import ErrorContent from 'src/components/error-content';
import { useSettingsContext } from 'src/components/settings';
import { LeftIcon } from 'src/components/carousel/arrow-icons';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import { LoadingScreen } from '../../../components/loading-screen';
import { useAppDispatcher, useAppSelector } from '../../../redux/store';
import KitchenPurchaseOrderDetails from '../kitchen-purchase-order-details';

type Props = {
  id: string;
};

export default function KitchenPurchaseOrderDetailView({ id }: Props) {
  const { item, status, error } = useAppSelector((state) => state.kitchenPurchaseOrders);

  const settings = useSettingsContext();
  const dispatch = useAppDispatcher();

  const smDown = useResponsive('down', 'sm');

  useEffect(() => {
    dispatch(getKitchenPurchaseOrderById({ itemID: id }));
  }, [dispatch, id]);

  if (status === 'loading' || status === 'idle') {
    return <LoadingScreen />;
  }

  if (status === 'failure') {
    return (
      <ErrorContent
        filled
        title="Failed to load kitchen purchase order details"
        description={error}
        action={
          <Button
            component={RouterLink}
            href={paths.dashboard.kitchen.purchase.root()}
            variant="contained"
            startIcon={<LeftIcon />}
          >
            Back to Kitchen Purchase Orders
          </Button>
        }
        sx={{
          py: 10,
        }}
      />
    );
  }

  if (status === 'success' && item) {
    return (
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Kitchen Purchase Order Details"
          links={[
            ...(smDown
              ? []
              : [
                  {
                    name: 'Kitchen Purchase Orders',
                    href: paths.dashboard.kitchen.purchase.root(),
                  },
                ]),
            { name: 'Kitchen Purchase Order Details' },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <KitchenPurchaseOrderDetails currentKitchenPurchaseOrder={item} />
      </Container>
    );
  }

  return <span />;
}
