import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Autocomplete, Button, Divider, InputAdornment, Link, TextField } from '@mui/material';

import { RouterLink } from 'src/routes/components';

import { InventoryItemGroup } from 'src/utils/common-types';

import { BAR_CATEGORY_ID, UNIT_ID } from 'src/config-global';
import { getPaginatedSuppliers } from 'src/redux/slices/suppliers';
import { useAppDispatcher, useAppSelector } from 'src/redux/store';
import { createBarItem, TCreateBarItem, updateBarItem } from 'src/redux/slices/bar';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';

import { IBarItem } from 'src/types/bar';
import { ISupplierListItem } from 'src/types/supplier';

import { paths } from '../../routes/paths';

export type ConditionalSchema<T> = T extends string
  ? Yup.StringSchema
  : T extends number
  ? Yup.NumberSchema
  : T extends boolean
  ? Yup.BooleanSchema
  : T extends Record<any, any>
  ? Yup.AnyObjectSchema
  : T extends Array<any>
  ? Yup.ArraySchema<any, any>
  : Yup.AnySchema;

export type Shape<Fields> = {
  [Key in keyof Fields]: ConditionalSchema<Fields[Key]>;
};

type Props = {
  currentBarItem?: IBarItem;
};

export default function BarNewEditForm({ currentBarItem }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useAppDispatcher();

  const { suppliers } = useAppSelector((state) => state.suppliers);

  const [selectedSuppliers, setSelectedSuppliers] = useState<ISupplierListItem[]>(
    currentBarItem?.suppliers ?? []
  );
  const [localSuppliers, setLocalSuppliers] = useState<ISupplierListItem[]>([]);

  const NewBarItemSchema = Yup.object<Shape<TCreateBarItem>>().shape({
    itemName: Yup.string().required('Item name is required'),
    quantity: Yup.number().required('Quantity is required'),
    price: Yup.number().min(1).required('Selling price is required'),
    unit: Yup.string().required('Item unit is required'),
    group: Yup.string().required('Item group is required'),
    volume: Yup.number().min(0, 'volume must be greater than 0').required('Volume is required'),
    category: Yup.array(Yup.string().required('Item category is required')).min(1).default([]),
    reorderThreshold: Yup.number().required('Re-order threshold is required'),
    alertEnabled: Yup.boolean().optional().default(false),
    suppliers: Yup.array(Yup.string().required('Supplier ID is required'))
      .min(1, 'At least one supplier is required')
      .default([]),
  });

  const defaultValues = useMemo(
    () => ({
      itemName: currentBarItem?.itemName || '',
      quantity: currentBarItem?.quantity || 0,
      unit: currentBarItem?.unit._id || UNIT_ID,
      category: currentBarItem?.category.map((category) => category._id) || [BAR_CATEGORY_ID],
      reorderThreshold: currentBarItem?.reorderThreshold || 0,
      alertEnabled: currentBarItem?.alertEnabled || false,
      suppliers: currentBarItem?.suppliers.map((supplier) => supplier._id) || [],
      price: currentBarItem?.price || 0,
      group: currentBarItem?.group || '',
      volume: currentBarItem?.volume || 0,
    }),
    [currentBarItem]
  );

  const methods = useForm({
    resolver: yupResolver(NewBarItemSchema),
    defaultValues,
    mode: 'onChange',
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    if (currentBarItem) {
      // update
      try {
        await dispatch(
          updateBarItem({
            inventoryId: currentBarItem._id,
            updatingInventory: {
              ...data,
              itemName: data.itemName.trimStart().replace(/^\w/, (c) => c.toUpperCase()),
              suppliers: selectedSuppliers.map((supplier) => supplier._id),
            },
          })
        ).unwrap();

        enqueueSnackbar('Bar item edit success');
        navigate(paths.dashboard.bar.root());
        reset();
      } catch (e) {
        if (typeof e === 'string') {
          enqueueSnackbar(e, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(e?.message, {
            variant: 'error',
          });
        }
      }
    } else {
      try {
        await dispatch(
          createBarItem({
            newInventory: {
              ...data,
              itemName: data.itemName.trimStart().replace(/^\w/, (c) => c.toUpperCase()),
              suppliers: selectedSuppliers.map((supplier) => supplier._id),
            },
          })
        ).unwrap();

        enqueueSnackbar('Bar item creation success');
        navigate(paths.dashboard.bar.root());
        reset();
      } catch (e) {
        if (typeof e === 'string') {
          enqueueSnackbar(e, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(e?.message, {
            variant: 'error',
          });
        }
      }
    }
  });

  const fetchPaginatedSuppliers = useCallback(
    () =>
      dispatch(
        getPaginatedSuppliers({
          page: null,
          limit: null,
          generalSearch: null,
          sort: 'asc',
          sortBy: 'supplierName',
          categories: [BAR_CATEGORY_ID],
        })
      ),
    [dispatch]
  );

  useEffect(() => {
    setLocalSuppliers(suppliers.suppliers);
  }, [suppliers]);

  useEffect(() => {
    fetchPaginatedSuppliers();
  }, [fetchPaginatedSuppliers]);

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Card sx={{ p: 3 }}>
            <Grid container spacing={2}>
              <Grid
                mb={1}
                display="flex"
                mt={2}
                alignItems="center"
                justifyContent="space-between"
                width={1}
              >
                <Typography variant="subtitle2">Basic Information</Typography>
              </Grid>

              <Grid xs={12} md={6}>
                <RHFTextField required name="itemName" label="Item name" />
              </Grid>

              <Grid xs={12} md={6}>
                <RHFTextField
                  required
                  name="price"
                  label="Selling Price"
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                  }}
                />
              </Grid>

              <Grid xs={12} md={6}>
                <RHFAutocomplete
                  name="group"
                  options={Object.values(InventoryItemGroup)}
                  placeholder="Select a group"
                  label="Select a group"
                  required
                />
              </Grid>

              <Grid xs={12} md={6}>
                <RHFTextField
                  required
                  name="volume"
                  label="Volume"
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">litre</InputAdornment>,
                  }}
                />
              </Grid>

              <Divider
                sx={{
                  width: 1,
                  my: 2,
                  borderStyle: 'dashed',
                }}
              />

              <Grid mb={1} xs={12} width="full">
                <Typography mt={2} variant="subtitle2">
                  Supplier Information
                </Typography>
              </Grid>

              <Grid xs={12}>
                <Controller
                  name="suppliers"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      options={localSuppliers}
                      getOptionLabel={(option) =>
                        typeof option === 'string' ? option : option.supplierName
                      }
                      autoComplete
                      isOptionEqualToValue={(option, value) => option._id === value._id}
                      filterSelectedOptions
                      value={selectedSuppliers}
                      onChange={(event: any, newValue: ISupplierListItem[] | null) => {
                        setSelectedSuppliers(newValue ?? []);

                        field.onChange(newValue?.map((item) => item._id));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search for suppliers..."
                          error={!!error}
                          helperText={error ? error.message : ' '}
                          fullWidth
                        />
                      )}
                      renderOption={(props, option) => (
                        <li key={option._id} {...props}>
                          <Typography
                            variant="body2"
                            sx={{ color: 'text.secondary', textTransform: 'capitalize' }}
                          >
                            {option.supplierName}
                          </Typography>
                        </li>
                      )}
                    />
                  )}
                />
              </Grid>

              <Divider
                sx={{
                  width: 1,
                  my: 2.5,
                  borderStyle: 'dashed',
                }}
              />

              <Grid mb={1} xs={12} width="full">
                <Typography mt={2} variant="subtitle2">
                  Additional Information
                </Typography>
              </Grid>

              <Grid xs={12} md={6}>
                <RHFTextField name="reorderThreshold" label="Re-order Threshold" type="number" />
              </Grid>

              <Grid xs={12} md={6}>
                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <Controller
                      name="alertEnabled"
                      control={control}
                      render={({ field }) => <Switch {...field} checked={field.value} />}
                    />
                  }
                  label={
                    <>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Stock Alerts Enabled
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Receive stock alerts when stock is low or running low
                      </Typography>
                    </>
                  }
                  sx={{ mx: 0, mb: 3, width: 1, justifyContent: 'space-between' }}
                />
              </Grid>
            </Grid>

            <Stack
              direction={{
                xs: 'column',
                sm: 'row',
              }}
              justifyContent={{
                xs: 'unset',
                sm: 'end',
              }}
              gap={2}
              mt={4}
            >
              <Link
                component={RouterLink}
                href={paths.dashboard.bar.root()}
                sx={{
                  width: {
                    xs: 1,
                    sm: 'auto',
                  },
                }}
              >
                <Button
                  variant="outlined"
                  color="inherit"
                  sx={{
                    width: 1,
                  }}
                >
                  Cancel
                </Button>
              </Link>

              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!currentBarItem ? 'Create Bar Item' : 'Save Changes'}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
