import Button from '@mui/material/Button';
import { Link, Tooltip } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';

import { fDateTime } from 'src/utils/format-time';
import { fCurrencyRupees } from 'src/utils/format-number';
import { hasPermission } from 'src/utils/has-permissions';

import { useAuthContext } from 'src/auth/hooks';
import { AuthPermissions } from 'src/auth/permissions';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

import { DocumentStatus } from 'src/types/common';
import { BarAvailabilityStatus, IBarListItem } from 'src/types/bar';

type Props = {
  selected: boolean;
  onEditRow: VoidFunction;
  row: IBarListItem;
  onDeleteRow: VoidFunction;
  onReActivateRow: VoidFunction;
};

export default function BarTableRow({
  row,
  selected,
  onEditRow,
  onDeleteRow,
  onReActivateRow,
}: Props) {
  const { _id, itemName, quantity, updatedAt, itemCode, unit, availability, price, status } = row;

  const { user: authUser } = useAuthContext();

  const confirm = useBoolean();

  const reActivateConfirm = useBoolean();

  const popover = usePopover();

  return (
    <>
      <Link
        component={RouterLink}
        href={paths.dashboard.bar.view(_id)}
        sx={{
          textDecoration: 'none',
          color: 'inherit',
          display: 'contents',
        }}
      >
        <TableRow hover selected={selected}>
          <TableCell sx={{ display: 'flex', alignItems: 'left' }}>
            <ListItemText
              primary={itemName}
              secondary={itemCode}
              primaryTypographyProps={{ typography: 'body2' }}
              secondaryTypographyProps={{
                component: 'span',
                color: 'text.disabled',
              }}
            />
          </TableCell>

          <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
            {quantity} {unit.abbreviation}
          </TableCell>

          <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
            {fCurrencyRupees(price * 100)}
          </TableCell>

          <TableCell sx={{ textAlign: 'left' }}>
            <Label
              variant="soft"
              color={
                (availability === BarAvailabilityStatus.IN_STOCK && 'success') ||
                (availability === BarAvailabilityStatus.OUT_OF_STOCK && 'error') ||
                (availability === BarAvailabilityStatus.RUNNING_LOW && 'warning') ||
                'default'
              }
            >
              {availability.split('-').join(' ')}
            </Label>
          </TableCell>

          <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
            {fDateTime(updatedAt)}
          </TableCell>

          <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
            <Link component={RouterLink} href={paths.dashboard.bar.view(_id)} mr={2}>
              <Button variant="soft" color="primary">
                View More
              </Button>
            </Link>

            {hasPermission(
              authUser,
              'any',
              AuthPermissions.UPDATE_BAR_ITEM,
              AuthPermissions.DELETE_BAR_ITEM,
              AuthPermissions.ADMIN_PERMISSION,
              AuthPermissions.SUPER_ADMIN_PERMISSION
            ) && (
              <Tooltip title="More options">
                <IconButton
                  color={popover.open ? 'inherit' : 'default'}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    popover.onOpen(e);
                  }}
                >
                  <Iconify icon="eva:more-vertical-fill" />
                </IconButton>
              </Tooltip>
            )}
          </TableCell>
        </TableRow>
      </Link>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        {hasPermission(
          authUser,
          'any',
          AuthPermissions.UPDATE_BAR_ITEM,
          AuthPermissions.ADMIN_PERMISSION,
          AuthPermissions.SUPER_ADMIN_PERMISSION
        ) && (
          <MenuItem
            onClick={() => {
              onEditRow();
              popover.onClose();
            }}
          >
            <Iconify icon="solar:pen-bold" />
            Edit
          </MenuItem>
        )}

        {hasPermission(
          authUser,
          'any',
          AuthPermissions.DELETE_BAR_ITEM,
          AuthPermissions.ADMIN_PERMISSION,
          AuthPermissions.SUPER_ADMIN_PERMISSION
        ) && status === DocumentStatus.ACTIVE ? (
          authUser?._id !== _id && (
            <MenuItem
              onClick={() => {
                confirm.onTrue();
                popover.onClose();
              }}
              sx={{ color: 'error.main' }}
            >
              <Iconify icon="solar:trash-bin-trash-bold" />
              Delete
            </MenuItem>
          )
        ) : (
          <MenuItem
            onClick={() => {
              reActivateConfirm.onTrue();
              popover.onClose();
            }}
            sx={{ color: 'error.main' }}
          >
            <Iconify icon="solar:undo-left-bold" />
            Re-Active
          </MenuItem>
        )}
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Warning : Permanent Deletion"
        content={`Are you sure you want to permanently delete ${itemName}'s details from the system? This action cannot be undone.`}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              confirm.onFalse();
              onDeleteRow();
            }}
          >
            Delete
          </Button>
        }
      />

      <ConfirmDialog
        open={reActivateConfirm.value}
        onClose={reActivateConfirm.onFalse}
        title="Warning : Permanent Deletion"
        content={`Are you sure you want to permanently re-activate ${itemName}'s details from the system? This action cannot be undone.`}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              reActivateConfirm.onFalse();
              onReActivateRow();
            }}
          >
            Re-Activate
          </Button>
        }
      />
    </>
  );
}
