import { TAB_QUERY } from 'src/utils/common-types';

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  pubsira: 'https://pubsira.com',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/login`,
      register: `${ROOTS.AUTH}/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: `${ROOTS.DASHBOARD}/analytics`,
    // permission: `${ROOTS.DASHBOARD}/permission`,
    // general: {
    //   app: `${ROOTS.DASHBOARD}/app`,
    //   ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
    //   analytics: `${ROOTS.DASHBOARD}/analytics`,
    //   banking: `${ROOTS.DASHBOARD}/banking`,
    //   booking: `${ROOTS.DASHBOARD}/booking`,
    //   file: `${ROOTS.DASHBOARD}/file`,
    // },
    user: {
      root: (defaultTab?: string) => `${ROOTS.DASHBOARD}/user?${TAB_QUERY}=${defaultTab ?? ''}`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      view: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/view`,
    },
    kitchen: {
      root: (defaultTab?: string) => `${ROOTS.DASHBOARD}/kitchen?${TAB_QUERY}=${defaultTab ?? ''}`,
      new: `${ROOTS.DASHBOARD}/kitchen/new`,
      view: (id: string) => `${ROOTS.DASHBOARD}/kitchen/${id}/view`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/kitchen/${id}/edit`,
      purchase: {
        root: (defaultTab?: string) =>
          `${ROOTS.DASHBOARD}/kitchen-purchase?${TAB_QUERY}=${defaultTab ?? ''}`,
        new: `${ROOTS.DASHBOARD}/kitchen-purchase/new`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/kitchen-purchase/${id}/edit`,
        view: (id: string) => `${ROOTS.DASHBOARD}/kitchen-purchase/${id}/view`,
      },
      dispatch: {
        root: (defaultTab?: string) =>
          `${ROOTS.DASHBOARD}/kitchen-dispatch?${TAB_QUERY}=${defaultTab ?? ''}`,
        new: `${ROOTS.DASHBOARD}/kitchen-dispatch/new`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/kitchen-dispatch/${id}/edit`,
        view: (id: string) => `${ROOTS.DASHBOARD}/kitchen-dispatch/${id}/view`,
      },
    },
    bar: {
      root: (defaultTab?: string) => `${ROOTS.DASHBOARD}/bar?${TAB_QUERY}=${defaultTab ?? ''}`,
      new: `${ROOTS.DASHBOARD}/bar/new`,
      view: (id: string) => `${ROOTS.DASHBOARD}/bar/${id}/view`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/bar/${id}/edit`,
      purchase: {
        root: (defaultTab?: string) =>
          `${ROOTS.DASHBOARD}/bar-purchase?${TAB_QUERY}=${defaultTab ?? ''}`,
        new: `${ROOTS.DASHBOARD}/bar-purchase/new`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/bar-purchase/${id}/edit`,
        view: (id: string) => `${ROOTS.DASHBOARD}/bar-purchase/${id}/view`,
      },
      dispatch: {
        root: (defaultTab?: string) =>
          `${ROOTS.DASHBOARD}/bar-dispatch?${TAB_QUERY}=${defaultTab ?? ''}`,
        new: `${ROOTS.DASHBOARD}/bar-dispatch/new`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/bar-dispatch/${id}/edit`,
        view: (id: string) => `${ROOTS.DASHBOARD}/bar-dispatch/${id}/view`,
      },
    },
    suppliers: {
      root: `${ROOTS.DASHBOARD}/suppliers`,
      new: `${ROOTS.DASHBOARD}/suppliers/new`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/suppliers/${id}/edit`,
      view: (id: string) => `${ROOTS.DASHBOARD}/suppliers/${id}/view`,
    },
    customers: {
      root: `${ROOTS.DASHBOARD}/customers`,
      new: `${ROOTS.DASHBOARD}/customers/new`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/customers/${id}/edit`,
      view: (id: string) => `${ROOTS.DASHBOARD}/customers/${id}/view`,
    },
    sales: {
      root: `${ROOTS.DASHBOARD}/sales`,
      new: `${ROOTS.DASHBOARD}/sales/new`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/sales/${id}/edit`,
    },
    customer: {
      root: `${ROOTS.DASHBOARD}/contact`,
      new: `${ROOTS.DASHBOARD}/contact/new`,
      list: `${ROOTS.DASHBOARD}/contact/list`,
      cards: `${ROOTS.DASHBOARD}/contact/cards`,
      profile: `${ROOTS.DASHBOARD}/contact/profile`,
      account: `${ROOTS.DASHBOARD}/contact/account`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/contact/${id}/edit`,
    },
    product: {
      root: `${ROOTS.DASHBOARD}/inventory`,
      new: `${ROOTS.DASHBOARD}/inventory/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/inventory/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/inventory/${id}/edit`,
    },
    project: {
      root: `${ROOTS.DASHBOARD}/project`,
      new: `${ROOTS.DASHBOARD}/project/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/project/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/project/${id}/edit`,
    },
    invoice: {
      root: `${ROOTS.DASHBOARD}/invoice`,
      new: `${ROOTS.DASHBOARD}/invoice/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      details: (id: string) => `${ROOTS.DASHBOARD}/order/${id}`,
    },
  },
};
