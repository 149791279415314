import { useEffect } from 'react';

import { Button } from '@mui/material';
import Container from '@mui/material/Container';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useResponsive } from 'src/hooks/use-responsive';

import { getCustomerById } from 'src/redux/slices/customers';

import ErrorContent from 'src/components/error-content';
import { useSettingsContext } from 'src/components/settings';
import { LeftIcon } from 'src/components/carousel/arrow-icons';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import CustomerNewEditForm from '../customer-new-edit-form';
import { LoadingScreen } from '../../../components/loading-screen';
import { useAppDispatcher, useAppSelector } from '../../../redux/store';

type Props = {
  id: string;
};

export default function CustomerEditView({ id }: Props) {
  const { customer, status, error } = useAppSelector((state) => state.customers);

  const settings = useSettingsContext();
  const dispatch = useAppDispatcher();

  const smDown = useResponsive('down', 'sm');

  useEffect(() => {
    dispatch(getCustomerById({ customerID: id }));
  }, [dispatch, id]);

  if (status === 'loading' || status === 'idle') {
    return <LoadingScreen />;
  }

  if (status === 'failure') {
    return (
      <ErrorContent
        filled
        title="Failed to load customer details"
        description={error}
        action={
          <Button
            component={RouterLink}
            href={paths.dashboard.customers.root}
            variant="contained"
            startIcon={<LeftIcon />}
          >
            Back to Users
          </Button>
        }
        sx={{
          py: 10,
        }}
      />
    );
  }

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Edit customer"
        links={[
          ...(smDown
            ? []
            : [
                {
                  name: 'Customer',
                  href: paths.dashboard.customers.root,
                },
              ]),
          { name: 'Edit customer' },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <CustomerNewEditForm currentCustomer={customer} />
    </Container>
  );
}
