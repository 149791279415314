import * as Yup from 'yup';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  FormHelperText,
  IconButton,
  InputAdornment,
  Link,
  ListItemText,
  Tooltip,
} from '@mui/material';

import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';

import { useAuthContext } from 'src/auth/hooks';
import {
  getPaginatedPermissions,
  IGroupedPermissions,
  IPermission,
} from 'src/redux/slices/permissions';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

import { paths } from '../../routes/paths';
import { AccountStatus } from '../../config-global';
import { useAppDispatcher, useAppSelector } from '../../redux/store';
import { createUser, IUser, TCreateUser, updateUser, UserStatus } from '../../redux/slices/users';

export type ConditionalSchema<T> = T extends string
  ? Yup.StringSchema
  : T extends number
  ? Yup.NumberSchema
  : T extends boolean
  ? Yup.BooleanSchema
  : T extends Record<any, any>
  ? Yup.AnyObjectSchema
  : T extends Array<any>
  ? Yup.ArraySchema<any, any>
  : Yup.AnySchema;

export type Shape<Fields> = {
  [Key in keyof Fields]: ConditionalSchema<Fields[Key]>;
};

type Props = {
  currentUser?: IUser;
};

const STRING_WITH_SPACE = ' ';

export default function UserNewEditForm({ currentUser }: Props) {
  const { user: authUser } = useAuthContext();

  const { permissions } = useAppSelector((state) => state.permissions);

  const dispatch = useAppDispatcher();

  const password = useBoolean();
  const confirmPassword = useBoolean();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const NewUserSchema = Yup.object<Shape<TCreateUser>>().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().optional().nullable().default(null),
    email: Yup.string().email('Incorrect email address').required('Email address is required'),
    password: currentUser
      ? Yup.string().required()
      : Yup.string()
          .min(5, 'At least 5 characters required')
          // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
          .required('Password is required'),
    confirmPassword: currentUser
      ? Yup.string().required()
      : Yup.string()
          .oneOf([Yup.ref('password')], 'Passwords must match')
          .required('Confirm Password is required'),
    jobRole: Yup.string().optional().nullable().default(null),
    status: Yup.mixed<UserStatus>()
      .oneOf(
        Object.values(UserStatus),
        `Status must be one of ${Object.values(UserStatus).join(', ')}`
      )
      .optional()
      .default(UserStatus.ACTIVE),
    permissions: Yup.array(
      Yup.object<Shape<IGroupedPermissions>>()
        .shape({
          name: Yup.string().required('Permission group is required'),
          description: Yup.string().required('Permission group description is required'),
          checked: Yup.boolean().required().default(false),
          permissions: Yup.array(
            Yup.object<Shape<IPermission>>()
              .shape({
                _id: Yup.string().required('Permission ID is required'),
                name: Yup.string().required('Permission name is required'),
                description: Yup.string().required('Permission description is required'),
                checked: Yup.boolean().required().default(false),
              })
              .required()
          ).default([]),
        })
        .required()
    ).default([]),
  });

  const defaultValues = useMemo<TCreateUser>(
    () => ({
      firstName: currentUser?.firstName || '',
      lastName: currentUser?.lastName || '',
      email: currentUser?.email || '',
      password: currentUser ? STRING_WITH_SPACE : '',
      confirmPassword: currentUser ? STRING_WITH_SPACE : '',
      jobRole: currentUser?.jobRole || '',
      status: currentUser?.status || UserStatus.ACTIVE,
      permissions: permissions.groupedPermissions,
      // permissions: [],
    }),
    [currentUser, permissions.groupedPermissions]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
    mode: 'onChange',
  });

  const {
    watch,
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    getValues,
  } = methods;

  const formPermissions = watch('permissions');
  const activeStatus = watch('status');

  useEffect(() => {
    if (permissions.groupedPermissions.length > 0) {
      reset({ ...defaultValues, permissions: permissions.groupedPermissions });
    }
  }, [permissions.groupedPermissions, reset, defaultValues]);

  const onSubmit = handleSubmit(async (data) => {
    if (currentUser) {
      // update
      try {
        await dispatch(
          updateUser({
            userId: currentUser._id,
            user: {
              ...data,
              firstName: data.firstName.trimStart().replace(/^\w/, (c) => c.toUpperCase()),
              permissions: data.permissions.flatMap((group) =>
                group.permissions
                  .filter((permission) => permission.checked)
                  .map((permission) => permission._id)
              ),
            },
          })
        ).unwrap();

        enqueueSnackbar('User edit success');

        if (data.status === UserStatus.ACTIVE) {
          navigate(paths.dashboard.user.root(UserStatus.ACTIVE));
        } else {
          navigate(paths.dashboard.user.root('blocked'));
        }

        reset();
      } catch (e) {
        if (typeof e === 'string') {
          enqueueSnackbar(e, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(e?.message, {
            variant: 'error',
          });
        }
      }
    } else {
      try {
        await dispatch(
          createUser({
            user: {
              ...data,
              firstName: data.firstName.trimStart().replace(/^\w/, (c) => c.toUpperCase()),
              permissions: data.permissions.flatMap((group) =>
                group.permissions
                  .filter((permission) => permission.checked)
                  .map((permission) => permission._id)
              ),
            },
          })
        ).unwrap();

        enqueueSnackbar('User registration success');
        navigate(paths.dashboard.user.root(UserStatus.ACTIVE));
        reset();
      } catch (e) {
        if (typeof e === 'string') {
          enqueueSnackbar(e, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(e?.message, {
            variant: 'error',
          });
        }
      }
    }
  });

  useEffect(() => {
    dispatch(getPaginatedPermissions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentUser) {
      formPermissions.forEach((group, groupIndex) => {
        const matchingUserGroup = currentUser.permissions.find(
          (userGroup) => userGroup.name === group.name
        );

        let allChecked = true;

        group.permissions.forEach((permission, index) => {
          const isChecked = matchingUserGroup
            ? matchingUserGroup.permissions
                .map((userPermission) => userPermission._id)
                .includes(permission._id)
            : false;

          setValue(`permissions.${groupIndex}.permissions.${index}.checked`, isChecked);

          if (!isChecked) {
            allChecked = false;
          }
        });

        setValue(`permissions.${groupIndex}.checked`, allChecked);
      });
    }
  }, [currentUser, formPermissions, setValue]);

  const renderGroupHeader = (group: any) => {
    const count = group.permissions.filter((permission: any) => permission.checked).length;

    return (
      <>
        <Typography variant="body2" mr={1}>
          {group.name}
        </Typography>
        {count > 0 && (
          <Label color="success">
            {count < 10
              ? `0${count} Permission${count > 1 ? 's' : ''}`
              : `${count} Permission ${count > 1 ? 's' : ''}`}
          </Label>
        )}
      </>
    );
  };

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Card sx={{ p: 3 }}>
            <Grid container spacing={2}>
              <Grid
                mb={1}
                display="flex"
                mt={2}
                alignItems="center"
                justifyContent="space-between"
                width={1}
              >
                <Typography variant="subtitle2">Account Information</Typography>

                {currentUser && (
                  <Label
                    color={
                      (currentUser.status === AccountStatus.ACTIVE && 'success') ||
                      (currentUser.status === AccountStatus.BLOCKED && 'error') ||
                      'warning'
                    }
                    // sx={{ position: 'absolute', top: 24, right: 24 }}
                  >
                    {currentUser.status === AccountStatus.ACTIVE ? 'Active' : 'Blocked'}
                  </Label>
                )}
              </Grid>

              <Grid xs={12}>
                <RHFTextField
                  disabled={!!currentUser}
                  required
                  name="email"
                  label="Email Address"
                  type="email"
                  autoComplete="one-time-code"
                />
              </Grid>

              {!currentUser && (
                <>
                  <Grid xs={12}>
                    <RHFTextField
                      required
                      name="password"
                      label="Password"
                      autoComplete="one-time-code"
                      type={password.value ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title={password.value ? 'Hide Password' : 'Show Password'}>
                              <IconButton onClick={password.onToggle} edge="end">
                                <Iconify
                                  icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                                />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        'data-test-id': 'login_password',
                      }}
                    />
                  </Grid>

                  <Grid xs={12}>
                    <RHFTextField
                      required
                      name="confirmPassword"
                      autoComplete="one-time-code"
                      label="Confirm Password"
                      type={confirmPassword.value ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                              title={confirmPassword.value ? 'Hide Password' : 'Show Password'}
                            >
                              <IconButton onClick={confirmPassword.onToggle} edge="end">
                                <Iconify
                                  icon={
                                    confirmPassword.value
                                      ? 'solar:eye-bold'
                                      : 'solar:eye-closed-bold'
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        'data-test-id': 'login_password',
                      }}
                    />
                  </Grid>
                </>
              )}

              <Divider
                sx={{
                  width: 1,
                  my: 2.5,
                  borderStyle: 'dashed',
                }}
              />

              <Grid mb={1} xs={12} width="full">
                <Typography mt={2} variant="subtitle2">
                  Personal & Occupation
                </Typography>
              </Grid>

              <Grid xs={12} md={6}>
                <RHFTextField required name="firstName" label="First Name" />
              </Grid>

              <Grid xs={12} md={6}>
                <RHFTextField name="lastName" label="Last Name" />
              </Grid>

              <Grid xs={12} md={6}>
                <RHFTextField name="jobRole" label="Job Role" />
              </Grid>

              {currentUser && (
                <Divider
                  sx={{
                    width: 1,
                    my: 2.5,
                    borderStyle: 'dashed',
                  }}
                />
              )}

              <Grid xs={12} md={6}>
                {currentUser && (
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Controller
                        name="status"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            {...field}
                            disabled={authUser?._id === currentUser?._id}
                            checked={field.value === 'active'}
                            onChange={(event) =>
                              field.onChange(
                                event.target.checked ? UserStatus.ACTIVE : UserStatus.DISABLED
                              )
                            }
                          />
                        )}
                      />
                    }
                    label={
                      <>
                        <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                          Active
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          Toggle {activeStatus === UserStatus.ACTIVE ? 'off' : 'on'} to{' '}
                          {activeStatus === UserStatus.ACTIVE ? 'disable' : 'enable'} account
                        </Typography>
                      </>
                    }
                    sx={{ mx: 0, mb: 3, width: 1, justifyContent: 'space-between' }}
                  />
                )}
              </Grid>

              <Divider
                sx={{
                  width: 1,
                  my: 2.5,
                  borderStyle: 'dashed',
                }}
              />

              <Grid mb={1} xs={12} width="full">
                <Typography mt={2} variant="subtitle2">
                  Permissions
                </Typography>
              </Grid>

              <Grid xs={12}>
                {formPermissions.map((group, groupIndex) => {
                  if (group) {
                    return (
                      <Accordion key={group.name}>
                        <AccordionSummary
                          expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}
                        >
                          {renderGroupHeader(group)}
                        </AccordionSummary>

                        <AccordionDetails>
                          {group.permissions.map((permission, index) => (
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              key={permission._id}
                              mb={2}
                            >
                              <Controller
                                control={control}
                                name={`permissions.${groupIndex}.permissions.${index}.checked`}
                                render={({ field, fieldState: { error } }) => (
                                  <div>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          {...field}
                                          disabled={authUser?._id === currentUser?._id}
                                          checked={field.value}
                                          onChange={(e) => {
                                            field.onChange(e.target.checked);

                                            const groupPermissions = getValues(
                                              `permissions.${groupIndex}.permissions`
                                            );
                                            const allChecked = groupPermissions.every(
                                              (perm) => perm.checked
                                            );

                                            setValue(
                                              `permissions.${groupIndex}.checked`,
                                              allChecked
                                            );
                                          }}
                                        />
                                      }
                                      label=""
                                    />

                                    {!!error && (
                                      <FormHelperText error={!!error}>
                                        {error && error?.message}
                                      </FormHelperText>
                                    )}
                                  </div>
                                )}
                              />

                              <ListItemText
                                primary={permission.name.toLocaleLowerCase().split('_').join(' ')}
                                secondary={permission.description}
                                primaryTypographyProps={{
                                  typography: 'body2',
                                  textTransform: 'capitalize',
                                }}
                                secondaryTypographyProps={{
                                  component: 'span',
                                  color: 'text.disabled',
                                }}
                              />
                            </Stack>
                          ))}

                          {group.permissions.length > 1 && (
                            <Stack direction="row" justifyContent="space" my={2}>
                              <Controller
                                control={control}
                                name={`permissions.${groupIndex}.checked`}
                                render={({ field, fieldState: { error } }) => (
                                  <div>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          {...field}
                                          disabled={authUser?._id === currentUser?._id}
                                          checked={field.value}
                                          onChange={(e) => {
                                            field.onChange(e.target.checked);

                                            group.permissions.map((_, index) => {
                                              setValue(
                                                `permissions.${groupIndex}.permissions.${index}.checked`,
                                                e.target.checked
                                              );
                                              return null;
                                            });
                                          }}
                                        />
                                      }
                                      label=""
                                    />

                                    {!!error && (
                                      <FormHelperText error={!!error}>
                                        {error && error?.message}
                                      </FormHelperText>
                                    )}
                                  </div>
                                )}
                              />

                              <ListItemText
                                primary="Allow all"
                                secondary={group.description}
                                primaryTypographyProps={{ typography: 'body2' }}
                                secondaryTypographyProps={{
                                  component: 'span',
                                  color: 'text.disabled',
                                }}
                              />
                            </Stack>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    );
                  }

                  return <span />;
                })}
              </Grid>
            </Grid>

            <Stack
              alignItems={{
                xs: 'unset',
                sm: 'center',
              }}
              justifyContent={{
                xs: 'unset',
                sm: 'end',
              }}
              sx={{ mt: 3 }}
              direction={{ xs: 'column', sm: 'row' }}
            >
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                gap={2}
                sx={{
                  width: {
                    xs: 1,
                    sm: 'unset',
                  },
                }}
              >
                <Link
                  component={RouterLink}
                  href={paths.dashboard.user.root(UserStatus.ACTIVE)}
                  sx={{
                    width: {
                      xs: 1,
                      sm: 'auto',
                    },
                  }}
                >
                  <Button
                    variant="outlined"
                    color="inherit"
                    sx={{
                      width: 1,
                    }}
                  >
                    Cancel
                  </Button>
                </Link>

                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  {!currentUser ? 'Create User' : 'Save Changes'}
                </LoadingButton>
              </Stack>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
