import Container from '@mui/material/Container';

import { paths } from 'src/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import SupplierNewEditForm from '../supplier-new-edit-form';

export default function SupplierCreateView() {
  const settings = useSettingsContext();

  const smDown = useResponsive('down', 'sm');

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Create a new supplier"
        links={[
          ...(smDown
            ? []
            : [
                {
                  name: 'Suppliers',
                  href: paths.dashboard.suppliers.root,
                },
              ]),
          { name: 'Create a new supplier' },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <SupplierNewEditForm />
    </Container>
  );
}
