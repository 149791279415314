import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { groupPermissionsByCategory, IGroupedPermissions } from './permissions';

export enum UserStatus {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  PENDING = 'pending',
  SUSPENDED = 'suspended',
  DELETED = 'deleted',
}

export enum AuthUserStatus {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  PENDING = 'pending',
  SUSPENDED = 'suspended',
  DELETED = 'deleted',
  SYSTEM = 'system',
}

export interface IListUser {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  jobRole: string;
  status: UserStatus;
  createdAt: string;
  updatedAt: string;
}

export interface IUser {
  _id: string;
  email: string;
  firstName: string;
  lastName: string | null;
  jobRole: string | null;
  status: UserStatus;
  permissions: IGroupedPermissions[];
  createdAt: string;
}

interface IInitialState {
  status: 'idle' | 'loading' | 'success' | 'failure';
  error: string | undefined;
  users: {
    count: number;
    page: number;
    pageSize: number;
    pageCount: number;
    users: IListUser[];
  };
  user: IListUser | null;
}

const initialState: IInitialState = {
  status: 'idle',
  error: undefined,
  users: {
    count: 0,
    page: 0,
    pageSize: 0,
    pageCount: 0,
    users: [],
  },
  user: null,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    removeUser: (state, action) => {
      // state.users.users = state.users.users.filter((user) => user._id !== action.payload.userId);

      const { userId } = action.payload;

      // Find the index of the user to be removed
      const index = state.users.users.findIndex((user) => user._id === userId);

      // Remove the user and save their original index in the action payload for rollback
      if (index > -1) {
        action.payload.index = index; // Store the index for rollback
        state.users.users = state.users.users.filter((user) => user._id !== userId);
      }
    },
    rollbackUserDeletion: (state, action) => {
      const { user, index } = action.payload;

      // Reinsert the user at the original index in the array
      state.users.users.splice(index, 0, user);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaginatedUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPaginatedUsers.fulfilled, (state, action) => {
        state.status = 'success';
        state.users = action.payload;
      })
      .addCase(getPaginatedUsers.rejected, (state, action) => {
        state.users = initialState.users;
        state.status = 'failure';
        state.error = action.error.message;
      })
      // Get User By ID
      .addCase(getUserById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.status = 'success';
        state.user = action.payload;
        state.error = initialState.error;
      })
      .addCase(getUserById.rejected, (state, action) => {
        state.user = initialState.user;
        state.status = 'failure';
        state.error = action.error.message;
      });
  },
});

export default usersSlice.reducer;

export const { removeUser, rollbackUserDeletion } = usersSlice.actions;

export const getPaginatedUsers = createAsyncThunk(
  'users/paginated',
  async (params: {
    page: number;
    limit: number;
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    permissions: string[];
    sortBy: string | null;
    sort: 'asc' | 'desc' | null;
    status: UserStatus[];
    createdBy?: string | null;
  }) => {
    try {
      const response = await axios.get(
        `user?page=${params.page + 1}&limit=${params.limit}&generalSearch=${
          params.firstName
        }&email=&firstName=&lastName=&jobRole=&permissions=${params.permissions.join(
          ','
        )}&status=${params.status.join('|')}&sortBy=${params.sortBy}&sort=${
          params.sort
        }&createdBy=${params.createdBy ?? ''}`
      );

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const getUserById = createAsyncThunk(
  'users/getUserById',
  async (params: { userId: string }) => {
    const { userId } = params;

    try {
      const response = await axios.get(`user/${userId}`);

      const { permissions } = response.data.data;

      return { ...response.data.data, permissions: groupPermissionsByCategory(permissions) };
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (params: { user: IListUser; index: number }, { dispatch }) => {
    const { user, index } = params;

    try {
      const response = await axios.delete(`user/${user._id}`);
      return response.data.data;
    } catch (err) {
      dispatch(rollbackUserDeletion({ user, index }));
      throw err;
    }
  }
);

export interface TCreateUser extends Omit<IUser, '_id' | 'createdAt' | 'permissions'> {
  password: string;
  confirmPassword: string;
  permissions: IGroupedPermissions[];
}

export interface TCreateUserSubmit extends Omit<TCreateUser, 'permissions'> {
  permissions: string[];
}

export const createUser = createAsyncThunk(
  'users/createUser',
  async (params: { user: TCreateUserSubmit }) => {
    const { user } = params;

    const response = await axios.post(`user`, user);

    return response.data.data;
  }
);

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async (params: { userId: string; user: any }) => {
    const { user, userId } = params;
    const response = await axios.put(`/user/${userId}`, user);
    return response.data.data;
  }
);

export interface TUserResetPassword {
  password: string;
  confirmPassword: string;
}

export const changePassword = createAsyncThunk(
  'users/changePassword',
  async (params: { userId: string; password: string }) => {
    const { userId, password } = params;
    const response = await axios.patch(`/user/change-password/${userId}`, {
      password,
    });
    return response.data.data;
  }
);

export const updateFCMToken = createAsyncThunk(
  'users/updateFCMToken',
  async (params: { fcmToken: string }) => {
    const { fcmToken } = params;
    const response = await axios.post('/user/fcm-token', {
      fcmToken,
    });
    return response.data.data;
  }
);
