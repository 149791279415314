import React, { useCallback, useEffect, useState } from 'react';

import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Skeleton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  TableRowProps,
  Typography,
} from '@mui/material';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';

import { InventoryItemGroup } from 'src/utils/common-types';

import { useAppDispatcher, useAppSelector } from 'src/redux/store';
import {
  getBarContribution,
  getBarSummarySnapshot,
  IContribution,
} from 'src/redux/slices/bar-summary';

import Scrollbar from 'src/components/scrollbar';
import EmptyContent from 'src/components/empty-content';
import TableErrorData from 'src/components/table/table-error-data';

import { IBarSummarySnapshotStats } from 'src/types/bar-summary';

import BarSummaryColumnChart from '../analytics-website-visits';

const LiquorTableCell = styled(TableCell)<TableCellProps>(({ theme }) => ({
  borderColor: theme.palette.divider,
  borderWidth: '1px',
}));

function BarSummarySnapshot() {
  const dispatch = useAppDispatcher();

  const open = useBoolean();

  const [selectedGroup, setSelectedGroup] = useState<{
    total: number;
    group: InventoryItemGroup;
  } | null>(null);

  const {
    snapshot: { data },
  } = useAppSelector((state) => state.barSummary);

  const summaryData: IBarSummarySnapshotStats | null = data;

  const fetchSummary = useCallback(() => {
    dispatch(getBarSummarySnapshot());
  }, [dispatch]);

  useEffect(() => {
    fetchSummary();
  }, [fetchSummary]);

  const selectGroup = useCallback(
    (group: InventoryItemGroup, total: number) => {
      setSelectedGroup({
        total,
        group,
      });
      open.onTrue();
    },
    [open]
  );

  return (
    <>
      <Stack>
        <Typography variant="h5">Bar Item Usage Snapshot</Typography>
        <Card sx={{ mt: 2, mb: 5 }}>
          <TableContainer sx={{ position: 'relative', overflow: 'hidden', height: '170px' }}>
            <Scrollbar>
              <Table stickyHeader size="small" sx={{ minWidth: 960 }}>
                <TableHead>
                  <TableRow>
                    <LiquorTableCell rowSpan={3} sx={{ minWidth: '200px' }}>
                      Particulars
                    </LiquorTableCell>
                    <LiquorTableCell rowSpan={2} sx={{ whiteSpace: 'nowrap' }}>
                      Malt Liquor (Beer Stout, Porter)
                    </LiquorTableCell>
                    <LiquorTableCell colSpan={2} align="center">
                      Wines
                    </LiquorTableCell>
                    <LiquorTableCell colSpan={5} align="center">
                      Spirits
                    </LiquorTableCell>
                    <LiquorTableCell rowSpan={2} align="center" sx={{ whiteSpace: 'nowrap' }}>
                      Liqueurs and Bitters
                    </LiquorTableCell>
                    <LiquorTableCell rowSpan={2} align="center" sx={{ whiteSpace: 'nowrap' }}>
                      Medicated Wines
                    </LiquorTableCell>
                    <LiquorTableCell rowSpan={2} align="center" sx={{ whiteSpace: 'nowrap' }}>
                      Miscellaneous Including Cider Milk Punch ang Ginger Wines
                    </LiquorTableCell>
                  </TableRow>
                  <TableRow>
                    <LiquorTableCell sx={{ whiteSpace: 'nowrap' }}>Sparkling Wine</LiquorTableCell>
                    <LiquorTableCell sx={{ whiteSpace: 'nowrap' }}>Other Wine</LiquorTableCell>
                    <LiquorTableCell>Whisky</LiquorTableCell>
                    <LiquorTableCell>Brandy</LiquorTableCell>
                    <LiquorTableCell>Rum</LiquorTableCell>
                    <LiquorTableCell>Gin</LiquorTableCell>
                    <LiquorTableCell sx={{ whiteSpace: 'nowrap' }}>
                      Rectified Spirits
                    </LiquorTableCell>
                  </TableRow>
                  <TableRow>
                    <LiquorTableCell>Litres</LiquorTableCell>
                    <LiquorTableCell>Litres</LiquorTableCell>
                    <LiquorTableCell>Litres</LiquorTableCell>

                    <LiquorTableCell>Litres</LiquorTableCell>
                    <LiquorTableCell>Litres</LiquorTableCell>
                    <LiquorTableCell>Litres</LiquorTableCell>
                    <LiquorTableCell>Litres</LiquorTableCell>
                    <LiquorTableCell>Litres</LiquorTableCell>

                    <LiquorTableCell>Litres</LiquorTableCell>
                    <LiquorTableCell>Litres</LiquorTableCell>
                    <LiquorTableCell>Litres</LiquorTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>In Hand</TableCell>
                    <TableCell
                      onClick={() => {
                        selectGroup(
                          InventoryItemGroup.MALT_LIQUOR,
                          summaryData?.['Malt Liquor'].totalVolume ?? 0
                        );
                      }}
                      sx={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      {summaryData?.['Malt Liquor'].totalVolume}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        selectGroup(
                          InventoryItemGroup.SPARKLING_WINES,
                          summaryData?.['Sparkling Wines'].totalVolume ?? 0
                        );
                      }}
                      sx={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      {summaryData?.['Sparkling Wines'].totalVolume}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        selectGroup(
                          InventoryItemGroup.OTHER_WINES,
                          summaryData?.['Other Wines'].totalVolume ?? 0
                        );
                      }}
                      sx={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      {summaryData?.['Other Wines'].totalVolume}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        selectGroup(
                          InventoryItemGroup.WHISKY,
                          summaryData?.Whisky.totalVolume ?? 0
                        );
                      }}
                      sx={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      {summaryData?.Whisky.totalVolume}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        selectGroup(
                          InventoryItemGroup.BRANDY,
                          summaryData?.Brandy.totalVolume ?? 0
                        );
                      }}
                      sx={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      {summaryData?.Brandy.totalVolume}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        selectGroup(InventoryItemGroup.RUM, summaryData?.Rum.totalVolume ?? 0);
                      }}
                      sx={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      {summaryData?.Rum.totalVolume}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        selectGroup(InventoryItemGroup.GIN, summaryData?.Gin.totalVolume ?? 0);
                      }}
                      sx={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      {summaryData?.Gin.totalVolume}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        selectGroup(
                          InventoryItemGroup.RECTIFIED_SPRITS,
                          summaryData?.['Rectified Spirits'].totalVolume ?? 0
                        );
                      }}
                      sx={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      {summaryData?.['Rectified Spirits'].totalVolume}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        selectGroup(
                          InventoryItemGroup.LIQUEURS_BITTERS,
                          summaryData?.['Liqueurs and Bitters'].totalVolume ?? 0
                        );
                      }}
                      sx={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      {summaryData?.['Liqueurs and Bitters'].totalVolume}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        selectGroup(
                          InventoryItemGroup.MEDICATED_WINES,
                          summaryData?.['Medicated Wines'].totalVolume ?? 0
                        );
                      }}
                      sx={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      {summaryData?.['Medicated Wines'].totalVolume}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        selectGroup(
                          InventoryItemGroup.MISCELLANEOUS,
                          summaryData?.Miscellaneous.totalVolume ?? 0
                        );
                      }}
                      sx={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      {summaryData?.Miscellaneous.totalVolume}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
        </Card>

        <BarSummaryColumnChart
          title=""
          subheader=""
          chart={{
            labels: Object.values(InventoryItemGroup),
            series: [
              {
                name: 'Purchase Volume',
                type: 'column',
                fill: 'solid',
                data: summaryData
                  ? Object.values(InventoryItemGroup).map((group) => summaryData[group].totalVolume)
                  : [],
              },
            ],
            options: {
              xaxis: {
                labels: {
                  rotateAlways: true,
                },
              },
            },
          }}
        />
      </Stack>
      <ContributionDetails
        open={open.value}
        onClose={() => {
          open.onFalse();
          setSelectedGroup(null);
        }}
        group={selectedGroup}
      />
    </>
  );
}

export default BarSummarySnapshot;

interface IContributionDetails {
  open: boolean;
  onClose: VoidFunction;
  group: {
    total: number;
    group: InventoryItemGroup;
  } | null;
}

function ContributionDetails({ group, onClose, open }: IContributionDetails) {
  const dispatch = useAppDispatcher();

  const {
    contribution: { data, status, error },
  } = useAppSelector((state) => state.barSummary);

  const fetchSummary = useCallback(() => {
    if (group) {
      dispatch(getBarContribution({ group: group.group, total: group.total }));
    }
  }, [dispatch, group]);

  useEffect(() => {
    if (open) {
      fetchSummary();
    }
  }, [fetchSummary, open]);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle sx={{ pb: 2 }}>
        {group?.group} ({group?.total} litres)
      </DialogTitle>

      <DialogContent sx={{ typography: 'body2' }}>
        <TableContainer
          sx={{ position: 'relative', overflow: 'hidden', minHeight: '300px', maxHeight: '300px' }}
        >
          <Scrollbar>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <LiquorTableCell sx={{ maxWidth: 100 }}>Bar Item</LiquorTableCell>
                  <LiquorTableCell>Quantity</LiquorTableCell>
                  <LiquorTableCell>Volume</LiquorTableCell>
                  <LiquorTableCell>Total Volume</LiquorTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {status === 'loading' ? (
                  [...Array(10)].map((i, index) => <TableSkeleton key={index} />)
                ) : (
                  <>
                    {data.map((item: IContribution) => (
                      <TableRow key={item._id}>
                        <TableCell
                          sx={{
                            maxWidth: {
                              md: 100,
                            },
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <Link component={RouterLink} href={paths.dashboard.bar.view(item._id)}>
                            {item.itemName}
                          </Link>
                        </TableCell>

                        <TableCell
                          sx={{
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {item.quantity} litres
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {item.volume} litres
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {item.totalVolume} litres
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}

                {status === 'success' && data.length === 0 && (
                  <TableRow>
                    {data.length === 0 ? (
                      <TableCell colSpan={4}>
                        <EmptyContent
                          filled
                          title="No Data Found"
                          sx={{
                            py: 2,
                          }}
                        />
                      </TableCell>
                    ) : (
                      <TableCell colSpan={4} sx={{ p: 0 }} />
                    )}
                  </TableRow>
                )}

                {status === 'idle' && (
                  <TableRow>
                    {data.length === 0 ? (
                      <TableCell colSpan={4}>
                        <EmptyContent
                          filled
                          title="No Data Found"
                          sx={{
                            py: 2,
                          }}
                        />
                      </TableCell>
                    ) : (
                      <TableCell colSpan={4} sx={{ p: 0 }} />
                    )}
                  </TableRow>
                )}

                {status === 'failure' && <TableErrorData notFound error={error} />}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function TableSkeleton({ ...other }: TableRowProps) {
  return (
    <TableRow {...other}>
      <TableCell colSpan={12}>
        <Stack spacing={3} direction="row" alignItems="center">
          <Skeleton sx={{ width: 1, height: 12 }} />
          <Skeleton sx={{ width: 180, height: 12 }} />
          <Skeleton sx={{ width: 160, height: 12 }} />
          <Skeleton sx={{ width: 140, height: 12 }} />
        </Stack>
      </TableCell>
    </TableRow>
  );
}
