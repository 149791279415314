import Container from '@mui/material/Container';

import { paths } from 'src/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import BarDispatchNewEditForm from '../bar-dispatch-new-edit-form';

export default function BarDispatchOrderCreateView() {
  const settings = useSettingsContext();

  const smDown = useResponsive('down', 'sm');

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Create a new dispatch order"
        links={[
          ...(smDown
            ? []
            : [
                {
                  name: 'Bar Dispatch Orders',
                  href: paths.dashboard.bar.dispatch.root(),
                },
              ]),
          { name: 'Create a new dispatch order' },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <BarDispatchNewEditForm />
    </Container>
  );
}
