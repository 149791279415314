import * as Yup from 'yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Divider, Link } from '@mui/material';

import { RouterLink } from 'src/routes/components';

import { SRILANKAN_PHONE_NUMBER } from 'src/utils/common-types';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

import { ICustomerItem } from 'src/types/customer';

import { paths } from '../../routes/paths';
import { useAppDispatcher } from '../../redux/store';
import { createCustomer, TCreateCustomer, updateCustomer } from '../../redux/slices/customers';

export type ConditionalSchema<T> = T extends string
  ? Yup.StringSchema
  : T extends number
  ? Yup.NumberSchema
  : T extends boolean
  ? Yup.BooleanSchema
  : T extends Record<any, any>
  ? Yup.AnyObjectSchema
  : T extends Array<any>
  ? Yup.ArraySchema<any, any>
  : Yup.AnySchema;

export type Shape<Fields> = {
  [Key in keyof Fields]: ConditionalSchema<Fields[Key]>;
};

type Props = {
  currentCustomer?: ICustomerItem;
};

export default function CustomerNewEditForm({ currentCustomer }: Props) {
  const dispatch = useAppDispatcher();

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const NewCustomerSchema = Yup.object<Shape<TCreateCustomer>>().shape({
    customerName: Yup.string().required('Customer name is required'),
    address: Yup.string().optional().nullable().default(null),
    email: Yup.string().email('Incorrect email address').optional().nullable().default(null),
    phoneNumber: Yup.string()
      .matches(SRILANKAN_PHONE_NUMBER, 'Invalid phone number')
      .optional()
      .nullable()
      .default(null),
    secondaryPhoneNumber: Yup.string()
      .matches(SRILANKAN_PHONE_NUMBER, 'Invalid phone number')
      .optional()
      .nullable()
      .default(null),
  });

  const defaultValues = useMemo(
    () => ({
      customerName: currentCustomer?.customerName || '',
      address: currentCustomer?.address || null,
      email: currentCustomer?.email || null,
      phoneNumber: currentCustomer?.phoneNumber || null,
      secondaryPhoneNumber: currentCustomer?.secondaryPhoneNumber || null,
    }),
    [currentCustomer]
  );

  const methods = useForm({
    resolver: yupResolver(NewCustomerSchema),
    defaultValues,
    mode: 'onChange',
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
    trigger,
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    if (currentCustomer) {
      // update
      try {
        await dispatch(
          updateCustomer({
            customerId: currentCustomer._id,
            customer: {
              ...data,
              customerName: data.customerName.trimStart().replace(/^\w/, (c) => c.toUpperCase()),
              creditLimit: 0,
            },
          })
        ).unwrap();

        enqueueSnackbar('Customer edit success');
        navigate(paths.dashboard.customers.root);
        reset();
      } catch (e) {
        if (typeof e === 'string') {
          enqueueSnackbar(e, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(e?.message, {
            variant: 'error',
          });
        }
      }
    } else {
      try {
        await dispatch(
          createCustomer({
            customer: {
              ...data,
              customerName: data.customerName.trimStart().replace(/^\w/, (c) => c.toUpperCase()),
              creditLimit: 0,
            },
          })
        ).unwrap();

        enqueueSnackbar('Customer creation success');

        navigate(paths.dashboard.customers.root);
        reset();
      } catch (e) {
        if (typeof e === 'string') {
          enqueueSnackbar(e, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(e?.message, {
            variant: 'error',
          });
        }
      }
    }
  });

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Card sx={{ p: 3 }}>
            <Grid container spacing={2}>
              <Grid
                mb={1}
                display="flex"
                mt={2}
                alignItems="center"
                justifyContent="space-between"
                width={1}
              >
                <Typography variant="subtitle2">Customer Information</Typography>
              </Grid>

              <Grid xs={12} md={6}>
                <RHFTextField required name="customerName" label="Name" />
              </Grid>

              <Grid xs={12} md={6}>
                <RHFTextField name="address" label="Address" />
              </Grid>

              <Grid xs={12} md={6}>
                <RHFTextField name="phoneNumber" label="Phone Number" type="tel" />
              </Grid>

              <Grid xs={12} md={6}>
                <RHFTextField
                  name="secondaryPhoneNumber"
                  label="Secondary Phone Number"
                  type="tel"
                />
              </Grid>

              <Grid xs={12} md={6}>
                <RHFTextField name="email" label="Email Address" type="email" />
              </Grid>

              <Divider
                sx={{
                  width: 1,
                  my: 2.5,
                  borderStyle: 'dashed',
                }}
              />
            </Grid>

            <Stack
              direction={{
                xs: 'column',
                sm: 'row',
              }}
              justifyContent={{
                xs: 'unset',
                sm: 'end',
              }}
              sx={{ mt: 3 }}
            >
              <Stack
                direction={{
                  xs: 'column',
                  sm: 'row',
                }}
                gap={2}
              >
                <Link
                  component={RouterLink}
                  href={paths.dashboard.customers.root}
                  sx={{
                    width: {
                      xs: 1,
                      sm: 'auto',
                    },
                  }}
                >
                  <Button
                    variant="outlined"
                    color="inherit"
                    sx={{
                      width: 1,
                    }}
                  >
                    Cancel
                  </Button>
                </Link>

                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  onClick={() => trigger()}
                >
                  {!currentCustomer ? 'Create Customer' : 'Save Changes'}
                </LoadingButton>
              </Stack>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
