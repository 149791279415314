import { AxiosError, HttpStatusCode } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ICustomerItem } from 'src/types/customer';
import { ICategoryListItem } from 'src/types/category';
import { ICommonItemTransactionListItem } from 'src/types/item';

import axios from '../../utils/axios';

interface IInitialState {
  status: 'idle' | 'loading' | 'success' | 'failure';
  error: string | undefined;
  customers: {
    count: number;
    page: number;
    pageSize: number;
    pageCount: number;
    customers: ICustomerItem[];
  };

  customer: ICustomerItem | null;

  transactions: {
    status: 'idle' | 'loading' | 'success' | 'failure';
    error: string | undefined;
    transactions: {
      count: number;
      page: number;
      pageSize: number;
      pageCount: number;
      transactions: ICommonItemTransactionListItem[];
    };
  };
}

const initialState: IInitialState = {
  status: 'idle',
  error: undefined,
  customers: {
    count: 0,
    page: 0,
    pageSize: 0,
    pageCount: 0,
    customers: [],
  },
  customer: null,
  transactions: {
    status: 'idle',
    error: undefined,
    transactions: {
      count: 0,
      page: 0,
      pageSize: 0,
      pageCount: 0,
      transactions: [],
    },
  },
};

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    removeCustomer: (state, action) => {
      const { customerId } = action.payload;

      const index = state.customers.customers.findIndex((customer) => customer._id === customerId);

      if (index > -1) {
        action.payload.index = index;
        state.customers.customers = state.customers.customers.filter(
          (customer) => customer._id !== customerId
        );
      }
    },
    rollbackCustomerDeletion: (state, action) => {
      const { customer, index } = action.payload;

      state.customers.customers.splice(index, 0, customer);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaginatedCustomers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPaginatedCustomers.fulfilled, (state, action) => {
        state.status = 'success';
        state.customers = action.payload;
      })
      .addCase(getPaginatedCustomers.rejected, (state, action) => {
        state.customers = initialState.customers;
        state.status = 'failure';
        state.error = action.error.message;
      })
      // Get Customer By ID
      .addCase(getCustomerById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCustomerById.fulfilled, (state, action) => {
        state.status = 'success';
        state.customer = action.payload;
        state.error = initialState.error;
      })
      .addCase(getCustomerById.rejected, (state, action) => {
        state.customer = initialState.customer;
        state.status = 'failure';
        state.error = action.error.message;
      })

      .addCase(getItemTransactionsByCustomer.pending, (state) => {
        state.transactions.status = 'loading';
      })
      .addCase(getItemTransactionsByCustomer.fulfilled, (state, action) => {
        state.transactions.status = 'success';
        state.transactions.transactions = action.payload;
      })
      .addCase(getItemTransactionsByCustomer.rejected, (state, action) => {
        state.transactions.status = 'failure';
        state.transactions.error = action.error.message;
      });
  },
});

export default customersSlice.reducer;

export const { removeCustomer, rollbackCustomerDeletion } = customersSlice.actions;

export const getPaginatedCustomers = createAsyncThunk(
  'customers/paginated',
  async (params: {
    page: number | null;
    limit: number | null;
    generalSearch: string | null;
    customerName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    secondaryPhoneNumber?: string | null;
    creditLimit?: number | null;
    address?: string | null;
    categories?: string[] | null;
    categoriesSeparator?: string | null;
    sortBy: string | null;
    sort: 'asc' | 'desc' | null;
  }) => {
    try {
      const response = await axios.get(
        `customer?page=${params.page !== null ? params.page + 1 : ''}&limit=${
          params.limit ?? ''
        }&generalSearch=${params.generalSearch ?? ''}&customerName=${
          params.customerName ?? ''
        }&email=${params.email ?? ''}&phoneNumber=${
          params.phoneNumber ?? ''
        }&secondaryPhoneNumber=${params.secondaryPhoneNumber ?? ''}&address=${
          params.address ?? ''
        }&status=active&creditLimitEnabled=
        &sortBy=${params.sortBy}&sort=${params.sort}&categories=${
          params.categories ? params.categories.join(params.categoriesSeparator ?? ',') : ''
        }&createdBy=`
      );

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const getCustomerById = createAsyncThunk(
  'customers/getCustomerById',
  async (params: { customerID: string }) => {
    const { customerID } = params;

    try {
      const response = await axios.get(`customer/${customerID}`);

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  'customer/deleteCustomer',
  async (params: { customer: ICustomerItem; index: number }, { dispatch }) => {
    const { customer, index } = params;

    try {
      const response = await axios.delete(`customer/${customer._id}`);
      return response.data.data;
    } catch (err) {
      if (err instanceof AxiosError && err.response?.status === HttpStatusCode.FailedDependency) {
        dispatch(rollbackCustomerDeletion({ customer, index }));
        throw new AxiosError(err.response.data.message);
      }
      throw err;
    }
  }
);

export const disableCustomer = createAsyncThunk(
  'customers/disableCustomer',
  async (params: { customer: any; index: number }, { dispatch }) => {
    const { customer, index } = params;
    try {
      const response = await axios.put(`customer/${customer._id}`, {
        ...customer,
        customerName: customer.customerName,
        email: customer.email,
        phoneNumber: customer.phoneNumber,
        secondaryPhoneNumber: customer.secondaryPhoneNumber,
        address: customer.address,
        creditLimit: customer.creditLimit,
        category: customer.category.map((category: ICategoryListItem) => category._id),
        status: 'disabled',
      });
      return response.data.data;
    } catch (err) {
      dispatch(rollbackCustomerDeletion({ customer, index }));
      throw err;
    }
  }
);

export interface TCreateCustomer
  extends Omit<ICustomerItem, '_id' | 'createdAt' | 'status' | 'updatedAt' | 'category'> {}

export const createCustomer = createAsyncThunk(
  'customers/createCustomer',
  async (params: { customer: TCreateCustomer }) => {
    const { customer } = params;

    const response = await axios.post(`customer`, customer);

    return response.data.data;
  }
);

export const updateCustomer = createAsyncThunk(
  'customers/updateCustomer',
  async (params: { customerId: string; customer: TCreateCustomer }) => {
    const { customer, customerId } = params;
    const response = await axios.put(`/customer/${customerId}`, customer);
    return response.data.data;
  }
);

// export const getInventoryItemsBySupplier = createAsyncThunk(
//   'suppliers/getInventoryItems',
//   async (params: { supplierId: string }) => {
//     const { supplierId } = params;
//     try {
//       const response = await axios.get(
//         `/inventory/item/by-supplier/${supplierId}?page&limit&sortBy=itemName&sort=asc`
//       );
//       return response.data.data.items;
//     } catch (err) {
//       console.log(err);
//       throw err;
//     }
//   }
// );

export const getItemTransactionsByCustomer = createAsyncThunk(
  'customers/getItemTransactionsByCustomer',
  async (params: {
    customer: string;
    page: number;
    limit: number;
    sort: 'asc' | 'desc';
    sortBy: string;
  }) => {
    try {
      const { customer, page, limit, sort, sortBy } = params;
      const response = await axios.get(
        `inventory/kitchen/transaction?page=${
          page + 1
        }&limit=${limit}&sortBy=${sortBy}&sort=${sort}&customers=${customer}&status=completed&generalSearch=&transactionCode=&categories=&isDiscounted=&minTotalValue=&maxTotalValue=&transactionType=&paymentStatus=&updatedBy=&purchaseOrDispatchDate=&createdBy=&suppliers=`
      );

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
