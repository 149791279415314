export enum DocumentStatus {
  ACTIVE = 'active',
  TEMPORARLY_DELETE = 'Temporarily Deleted',
}

export function getDocumentAPIStatus(status: string) {
  switch (status) {
    case DocumentStatus.ACTIVE:
      return 'active';
    case DocumentStatus.TEMPORARLY_DELETE:
      return 'disabled';
    default:
      return null;
  }
}
