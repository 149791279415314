import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  TRANSACTION_PAYMENT_STATUS,
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
} from 'src/utils/common-types';

import { KITCHEN_CATEGORY_ID } from 'src/config-global';

import {
  IKitchenPurchaseOrderDetailedItem,
  IKitchenPurchaseOrderListItem,
} from 'src/types/kitchen-purchase';

import axios from '../../utils/axios';

interface IInitialState {
  status: 'idle' | 'loading' | 'success' | 'failure';
  error: string | undefined;
  transactions: {
    count: number;
    page: number;
    pageSize: number;
    pageCount: number;
    transactions: IKitchenPurchaseOrderListItem[];
  };
  item: IKitchenPurchaseOrderDetailedItem | null;
}

const initialState: IInitialState = {
  status: 'idle',
  error: undefined,
  transactions: {
    count: 0,
    page: 1,
    pageSize: 10,
    pageCount: 0,
    transactions: [],
  },
  item: null,
};

const kitchenPurchaseOrders = createSlice({
  name: 'kitchenPurchaseOrders',
  initialState,
  reducers: {
    removeKitchenPurchaseOrderItem: (state, action) => {
      const { itemId } = action.payload;

      const index = state.transactions.transactions.findIndex((item) => item._id === itemId);

      if (index > -1) {
        action.payload.index = index; // Store the index for rollback
        state.transactions.transactions = state.transactions.transactions.filter(
          (item) => item._id !== itemId
        );
      }
    },
    rollbackKitchenPurchaseOrderDeletion: (state, action) => {
      const { item, index } = action.payload;

      state.transactions.transactions.splice(index, 0, item);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getKitchenPurchaseOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getKitchenPurchaseOrders.fulfilled, (state, action) => {
        state.status = 'success';
        state.transactions = action.payload;
      })
      .addCase(getKitchenPurchaseOrders.rejected, (state, action) => {
        state.status = 'failure';
        state.error = action.error.message;
      })
      .addCase(getKitchenPurchaseOrderById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getKitchenPurchaseOrderById.fulfilled, (state, action) => {
        state.status = 'success';
        state.item = action.payload;
      })
      .addCase(getKitchenPurchaseOrderById.rejected, (state, action) => {
        state.status = 'failure';
        state.error = action.error.message;
      });
  },
});

export default kitchenPurchaseOrders.reducer;

export const { removeKitchenPurchaseOrderItem, rollbackKitchenPurchaseOrderDeletion } =
  kitchenPurchaseOrders.actions;

export const getKitchenPurchaseOrders = createAsyncThunk(
  'kitchen/purchase/transactions',
  async (
    params: {
      page: number;
      limit: number;
      generalSearch?: string | null;
      status?: string | null;
      paymentStatus?: string | null;
      purchaseDate?: Date | null;
      minTotalValue?: number | null;
      maxTotalValue?: number | null;
      sortBy?: string | null;
      sort?: 'asc' | 'desc' | null;
    } = {
      page: 1,
      limit: 10,
      generalSearch: null,
      status: null,
      paymentStatus: null,
      purchaseDate: null,
      minTotalValue: null,
      maxTotalValue: null,
      sort: 'desc',
      sortBy: 'updatedAt',
    }
  ) => {
    const {
      page,
      limit,
      generalSearch,
      status,
      paymentStatus,
      purchaseDate,
      minTotalValue,
      maxTotalValue,
      sortBy,
      sort,
    } = params;

    try {
      const response = await axios.get(
        `inventory/kitchen/transaction?page=${page + 1}&limit=${limit}&generalSearch=${
          generalSearch ?? ''
        }&transactionCode=&status=${status ?? ''}&transactionType=purchase&paymentStatus=${
          paymentStatus ?? ''
        }&purchaseOrDispatchDate=${purchaseDate ?? ''}&minTotalValue=${
          minTotalValue ?? ''
        }&maxTotalValue=${
          maxTotalValue ?? ''
        }&isDiscounted=&suppliers=&categories=${KITCHEN_CATEGORY_ID}&updatedBy=&sortBy=${
          sortBy ?? ''
        }&sort=${sort ?? ''}&createdBy=&customers=`
      );

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const getKitchenPurchaseOrderById = createAsyncThunk(
  'kitchen/purchase/transaction/id',
  async (params: { itemID: string }) => {
    try {
      const { itemID } = params;
      const response = await axios.get(`inventory/kitchen/transaction/${itemID}`);

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const deleteKitchenPurchaseOrder = createAsyncThunk(
  'kitchen/purchase/delete',
  async (params: { item: IKitchenPurchaseOrderListItem; index: number }, { dispatch }) => {
    const { item, index } = params;

    try {
      const response = await axios.delete(`inventory/kitchen/transaction/${item._id}`);
      return response.data.data;
    } catch (err) {
      dispatch(rollbackKitchenPurchaseOrderDeletion({ item, index }));
      throw err;
    }
  }
);

export interface TCreateKitchenPurchaseOrderItem {
  inventoryItem: string;
  quantity: number;
  unit: string;
  // description: string | null;
  unitPrice: number;
  discount: number;
  // isPercentageDiscount: boolean;
  totalAfterDiscount: number;
  // purchaseDate: Date;
  // expiryDate: Date;
}

export interface TCreateKitchenPurchaseOrder {
  category: string;
  supplier: string | null;
  reason: string | null;
  description: string | null;
  discount: number;
  isPercentageDiscount: boolean;
  totalAfterDiscount: number;
  transactionType: TRANSACTION_TYPE;
  paymentStatus: TRANSACTION_PAYMENT_STATUS;
  purchaseOrDispatchDate: Date;
  status: TRANSACTION_STATUS;
  items: TCreateKitchenPurchaseOrderItem[];
}

export const createKitchenPurchaseOrder = createAsyncThunk(
  'kitchen/purchaseOrder/create',
  async (params: { poTransaction: TCreateKitchenPurchaseOrder }) => {
    const { poTransaction } = params;
    const response = await axios.post(`inventory/kitchen/transaction`, poTransaction);
    return response.data.data;
  }
);

export const updateKitchenPurchaseOrder = createAsyncThunk(
  'kitchen/purchaseOrder/update',
  async (params: { purchaseOrderId: string; poTransaction: TCreateKitchenPurchaseOrder }) => {
    const { poTransaction, purchaseOrderId } = params;
    const response = await axios.put(
      `inventory/kitchen/transaction/${purchaseOrderId}`,
      poTransaction
    );
    return response.data.data;
  }
);

export const updateKitchenPurchaseCompletedOrder = createAsyncThunk(
  'kitchen/purchaseOrder/completed/update',
  async (params: { purchaseOrderId: string; poTransaction: TCreateKitchenPurchaseOrder }) => {
    const { poTransaction, purchaseOrderId } = params;
    const response = await axios.put(`inventory/transaction/${purchaseOrderId}`, poTransaction);
    return response.data.data;
  }
);

export const kitchenPurchaseOrderMarkAsCompleted = createAsyncThunk(
  'kitchen/purchaseOrder/marAsCompleted',
  async (params: { purchaseOrderId: string; purchaseOrder: IKitchenPurchaseOrderDetailedItem }) => {
    const { purchaseOrderId, purchaseOrder } = params;

    const response = await axios.put(`/inventory/kitchen/transaction/${purchaseOrderId}`, {
      ...purchaseOrder,
      status: TRANSACTION_STATUS.COMPLETED,
      supplier: purchaseOrder.supplier?._id,
      items: purchaseOrder.items.map((item) => ({
        ...item,
        inventoryItem: item.inventoryItem._id,
        unit: item.unit._id,
      })),
    });
    return response.data.data;
  }
);
