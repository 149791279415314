import { Navigate } from 'react-router';
import { Helmet } from 'react-helmet-async';

import { useParams } from 'src/routes/hooks';

import { hasPermission } from 'src/utils/has-permissions';

import { useAuthContext } from 'src/auth/hooks';
import { AuthPermissions } from 'src/auth/permissions';

import { CustomerEditView } from 'src/sections/customer/view';

export default function CustomerEditPage() {
  const params = useParams();

  const { id } = params;

  const { user: authUser } = useAuthContext();

  return (
    <>
      <Helmet>
        <title>Customer Edit</title>
      </Helmet>

      {hasPermission(
        authUser,
        'any',
        AuthPermissions.UPDATE_CUSTOMER,
        AuthPermissions.ADMIN_PERMISSION,
        AuthPermissions.SUPER_ADMIN_PERMISSION
      ) ? (
        <CustomerEditView id={`${id}`} />
      ) : (
        <Navigate to="/403" />
      )}
    </>
  );
}
