import { useEffect } from 'react';

import { Button } from '@mui/material';
import Container from '@mui/material/Container';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useResponsive } from 'src/hooks/use-responsive';

import { getUserById } from 'src/redux/slices/users';

import ErrorContent from 'src/components/error-content';
import { useSettingsContext } from 'src/components/settings';
import { LeftIcon } from 'src/components/carousel/arrow-icons';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import UserDetails from '../user-details';
import { LoadingScreen } from '../../../components/loading-screen';
import { useAppDispatcher, useAppSelector } from '../../../redux/store';

type Props = {
  id: string;
};

export default function UserDetailView({ id }: Props) {
  const { user, status, error } = useAppSelector((state) => state.users);

  const settings = useSettingsContext();
  const dispatch = useAppDispatcher();

  const smDown = useResponsive('down', 'sm');

  useEffect(() => {
    dispatch(getUserById({ userId: id }));
  }, [dispatch, id]);

  if (status === 'loading' || status === 'idle') {
    return <LoadingScreen />;
  }

  if (status === 'failure') {
    return (
      <ErrorContent
        filled
        title="Failed to load user details"
        description={error}
        action={
          <Button
            component={RouterLink}
            href={paths.dashboard.user.root()}
            variant="contained"
            startIcon={<LeftIcon />}
          >
            Back to Users
          </Button>
        }
        sx={{
          py: 10,
        }}
      />
    );
  }

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="User Details"
        links={[
          ...(smDown
            ? []
            : [
                {
                  name: 'Users',
                  href: paths.dashboard.user.root(),
                },
              ]),
          { name: 'User Details' },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <UserDetails currentUser={user} />
    </Container>
  );
}
