import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import { hasPermission } from 'src/utils/has-permissions';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';
import { UserStatus } from 'src/redux/slices/users';
import { AuthPermissions } from 'src/auth/permissions';

import SvgColor from 'src/components/svg-color';

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  project: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useTranslate();

  const { user: authUser } = useAuthContext();

  const data = useMemo(() => {
    const navItems = [
      {
        subheader: t('dashboard'),
        items: [
          {
            title: t('analytics'),
            path: paths.dashboard.root,
            icon: ICONS.analytics,
          },
        ],
      },
    ];

    // Management Section
    const managementSection: {
      subheader: string;
      items: {
        title: string;
        path: string;
        icon: JSX.Element;
        children?: { title: string; path: string }[];
      }[];
    } = {
      subheader: t('management'),
      items: [],
    };

    if (
      hasPermission(
        authUser,
        'any',
        AuthPermissions.READ_USER,
        AuthPermissions.ADMIN_PERMISSION,
        AuthPermissions.SUPER_ADMIN_PERMISSION
      )
    ) {
      managementSection.items.push({
        title: t('user.pageTitle'),
        path: paths.dashboard.user.root(UserStatus.ACTIVE),
        icon: ICONS.user,
      });
    }

    if (
      hasPermission(
        authUser,
        'any',
        AuthPermissions.READ_SUPPLIER,
        AuthPermissions.ADMIN_PERMISSION,
        AuthPermissions.SUPER_ADMIN_PERMISSION
      )
    ) {
      managementSection.items.push({
        title: t('suppliers.pageTitle'),
        path: paths.dashboard.suppliers.root,
        icon: ICONS.blank,
      });
    }

    if (
      hasPermission(
        authUser,
        'any',
        AuthPermissions.READ_CUSTOMER,
        AuthPermissions.ADMIN_PERMISSION,
        AuthPermissions.SUPER_ADMIN_PERMISSION
      )
    ) {
      managementSection.items.push({
        title: t('customers.pageTitle'),
        path: paths.dashboard.customers.root,
        icon: ICONS.blank,
      });
    }

    const kitchenSection: {
      subheader: string;
      items: {
        title: string;
        path: string;
        icon: JSX.Element;
        children?: { title: string; path: string }[];
      }[];
    } = {
      subheader: t('kitchen.pageTitle'),
      items: [],
    };

    const barSection: {
      subheader: string;
      items: {
        title: string;
        path: string;
        icon: JSX.Element;
        children?: { title: string; path: string }[];
      }[];
    } = {
      subheader: t('bar.pageTitle'),
      items: [],
    };

    if (
      hasPermission(
        authUser,
        'any',
        AuthPermissions.READ_KITCHEN_ITEM,
        AuthPermissions.ADMIN_PERMISSION,
        AuthPermissions.SUPER_ADMIN_PERMISSION
      )
    ) {
      kitchenSection.items.push({
        title: t('kitchen.list'),
        path: paths.dashboard.kitchen.root(),
        icon: ICONS.product,
      });
    }

    if (
      hasPermission(
        authUser,
        'any',
        AuthPermissions.READ_KITCHEN_INVENTORY_TRANSACTION,
        AuthPermissions.ADMIN_PERMISSION,
        AuthPermissions.SUPER_ADMIN_PERMISSION
      )
    ) {
      kitchenSection.items.push({
        title: t('kitchen.purchase'),
        path: paths.dashboard.kitchen.purchase.root(),
        icon: ICONS.order,
      });

      kitchenSection.items.push({
        title: t('kitchen.dispatch'),
        path: paths.dashboard.kitchen.dispatch.root(),
        icon: ICONS.external,
      });
    }

    if (
      hasPermission(
        authUser,
        'any',
        AuthPermissions.READ_BAR_ITEM,
        AuthPermissions.ADMIN_PERMISSION,
        AuthPermissions.SUPER_ADMIN_PERMISSION
      )
    ) {
      barSection.items.push({
        title: t('bar.list'),
        path: paths.dashboard.bar.root(),
        icon: ICONS.product,
      });
    }

    if (
      hasPermission(
        authUser,
        'any',
        AuthPermissions.READ_BAR_INVENTORY_TRANSACTION,
        AuthPermissions.ADMIN_PERMISSION,
        AuthPermissions.SUPER_ADMIN_PERMISSION
      )
    ) {
      barSection.items.push({
        title: t('bar.purchase'),
        path: paths.dashboard.bar.purchase.root(),
        icon: ICONS.order,
      });

      barSection.items.push({
        title: t('bar.dispatch'),
        path: paths.dashboard.bar.dispatch.root(),
        icon: ICONS.external,
      });
    }

    if (managementSection.items.length > 0) {
      navItems.push(managementSection);
    }

    if (kitchenSection.items.length > 0) {
      navItems.push(kitchenSection);
    }

    if (barSection.items.length > 0) {
      navItems.push(barSection);
    }

    return navItems;
  }, [t, authUser]);

  return data;
}
