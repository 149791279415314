import * as Yup from 'yup';
import { differenceBy } from 'lodash';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { Autocomplete, Button, Divider, Link, TextField } from '@mui/material';

import { RouterLink } from 'src/routes/components';

import { SRILANKAN_PHONE_NUMBER } from 'src/utils/common-types';

import { getPaginatedCategories } from 'src/redux/slices/category';
import { getPaginatedItems, updateCommonInventoryItem } from 'src/redux/slices/item';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

import { ICommonInventoryItem } from 'src/types/item';
import { ICategoryListItem } from 'src/types/category';
import { ISupplierItem, ISupplierListItem } from 'src/types/supplier';

import { paths } from '../../routes/paths';
import { useAppDispatcher, useAppSelector } from '../../redux/store';
import { createSupplier, TCreateSupplier, updateSupplier } from '../../redux/slices/suppliers';

export type ConditionalSchema<T> = T extends string
  ? Yup.StringSchema
  : T extends number
  ? Yup.NumberSchema
  : T extends boolean
  ? Yup.BooleanSchema
  : T extends Record<any, any>
  ? Yup.AnyObjectSchema
  : T extends Array<any>
  ? Yup.ArraySchema<any, any>
  : Yup.AnySchema;

export type Shape<Fields> = {
  [Key in keyof Fields]: ConditionalSchema<Fields[Key]>;
};

type Props = {
  currentSupplier?: ISupplierItem;
};

export default function SupplierNewEditForm({ currentSupplier }: Props) {
  const dispatch = useAppDispatcher();

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const { items } = useAppSelector((state) => state.items);

  const { categories } = useAppSelector((state) => state.categories);

  const [selectedItems, setSelectedItems] = useState<ICommonInventoryItem[]>(
    currentSupplier?.inventoryItems ?? []
  );
  const [localItems, setLocalItems] = useState<ICommonInventoryItem[]>([]);

  const [selectedLocations, setSelectedLocations] = useState<ICategoryListItem[]>(
    currentSupplier?.category ?? []
  );
  const [localLocations, setLocalLocations] = useState<ICategoryListItem[]>([]);

  const NewSupplierSchema = Yup.object<Shape<TCreateSupplier>>().shape({
    supplierName: Yup.string().required('Supplier name is required'),
    contactPerson: Yup.string().required('Contact person is required'),
    email: Yup.string().email('Incorrect email address').optional().nullable().default(null),
    phoneNumber: Yup.string()
      .matches(SRILANKAN_PHONE_NUMBER, 'Invalid phone number')
      .optional()
      .nullable()
      .default(null),
    secondaryPhoneNumber: Yup.string()
      .matches(SRILANKAN_PHONE_NUMBER, 'Invalid phone number')
      .optional()
      .nullable()
      .default(null),
    category: Yup.array(Yup.string().required('Location is required'))
      .required('At least one location is required')
      .min(1, 'At least one location is required'),
  });

  const defaultValues = useMemo(
    () => ({
      supplierName: currentSupplier?.supplierName || '',
      contactPerson: currentSupplier?.contactPerson || '',
      email: currentSupplier?.email || null,
      phoneNumber: currentSupplier?.phoneNumber || null,
      secondaryPhoneNumber: currentSupplier?.secondaryPhoneNumber || null,
      category: currentSupplier?.category.map((category: ICategoryListItem) => category._id) || [],
    }),
    [currentSupplier]
  );

  const methods = useForm({
    resolver: yupResolver(NewSupplierSchema),
    defaultValues,
    mode: 'onChange',
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, errors },
    trigger,
    setValue,
    clearErrors,
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    if (currentSupplier) {
      // update
      try {
        const updatedSupplier: ISupplierListItem = await dispatch(
          updateSupplier({
            supplierId: currentSupplier._id,
            supplier: {
              ...data,
              supplierName: data.supplierName.trimStart().replace(/^\w/, (c) => c.toUpperCase()),
              category: selectedLocations.map((location) => location._id),
            },
          })
        ).unwrap();

        const removedItems = differenceBy(currentSupplier.inventoryItems, selectedItems, '_id');

        await Promise.all(
          removedItems.map(async (removedItem) => {
            await dispatch(
              updateCommonInventoryItem({
                inventoryId: removedItem._id,
                updatingInventory: removedItem,
                suppliers: removedItem.suppliers.filter(
                  (existingSupplier) => existingSupplier !== updatedSupplier._id
                ),
              })
            ).unwrap();
          })
        );

        await Promise.all(
          selectedItems.map(async (item) => {
            await dispatch(
              updateCommonInventoryItem({
                inventoryId: item._id,
                updatingInventory: item,
                suppliers: Array.from(new Set([...item.suppliers, updatedSupplier._id])),
              })
            ).unwrap();
          })
        );

        enqueueSnackbar('Supplier edit success');
        navigate(paths.dashboard.suppliers.root);
        reset();
      } catch (e) {
        if (typeof e === 'string') {
          enqueueSnackbar(e, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(e?.message, {
            variant: 'error',
          });
        }
      }
    } else {
      try {
        const createdSupplier: ISupplierListItem = await dispatch(
          createSupplier({
            supplier: {
              ...data,
              supplierName: data.supplierName.trimStart().replace(/^\w/, (c) => c.toUpperCase()),
              category: selectedLocations.map((location) => location._id),
            },
          })
        ).unwrap();

        await Promise.all(
          selectedItems.map(async (item) => {
            await dispatch(
              updateCommonInventoryItem({
                inventoryId: item._id,
                updatingInventory: item,
                suppliers: Array.from(new Set([...item.suppliers, createdSupplier._id])),
              })
            ).unwrap();
          })
        );

        enqueueSnackbar('Supplier creation success');

        navigate(paths.dashboard.suppliers.root);
        reset();
      } catch (e) {
        if (typeof e === 'string') {
          enqueueSnackbar(e, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(e?.message, {
            variant: 'error',
          });
        }
      }
    }
  });

  const fetchPaginatedItems = useCallback(
    () =>
      dispatch(
        getPaginatedItems({
          page: null,
          limit: null,
          generalSearch: null,
          sort: 'asc',
          sortBy: 'itemName',
        })
      ),
    [dispatch]
  );

  const fetchPaginatedLocations = useCallback(
    () =>
      dispatch(
        getPaginatedCategories({
          page: null,
          limit: null,
          generalSearch: null,
          sort: 'asc',
          sortBy: 'name',
        })
      ),
    [dispatch]
  );

  useEffect(() => {
    fetchPaginatedItems();
  }, [fetchPaginatedItems]);

  useEffect(() => {
    fetchPaginatedLocations();
  }, [fetchPaginatedLocations]);

  useEffect(() => {
    setLocalItems(items.items);
  }, [items]);

  useEffect(() => {
    setLocalLocations(categories.categories);
  }, [categories]);

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Card sx={{ p: 3 }}>
            <Grid container spacing={2}>
              <Grid
                mb={1}
                display="flex"
                mt={2}
                alignItems="center"
                justifyContent="space-between"
                width={1}
              >
                <Typography variant="subtitle2">Supplier Information</Typography>
              </Grid>

              <Grid xs={12} md={6}>
                <RHFTextField required name="supplierName" label="Name" />
              </Grid>

              <Grid xs={12} md={6}>
                <Autocomplete
                  multiple
                  options={localLocations}
                  getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                  autoComplete
                  isOptionEqualToValue={(option, value) => option._id === value._id}
                  filterSelectedOptions
                  value={selectedLocations}
                  onChange={(event: any, newValue: ICategoryListItem[] | null) => {
                    setSelectedLocations(newValue ?? []);

                    setValue(
                      'category',
                      newValue ? newValue.map((category: ICategoryListItem) => category._id) : []
                    );

                    clearErrors('category');
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Location(s)"
                      fullWidth
                      error={!!errors.category}
                      helperText={errors.category?.message}
                      InputLabelProps={{
                        required: true,
                      }}
                      inputProps={{
                        ...params.inputProps,
                        required: false,
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li key={option._id} {...props}>
                      <Typography
                        variant="body2"
                        sx={{ color: 'text.secondary', textTransform: 'capitalize' }}
                      >
                        {option.name}
                      </Typography>
                    </li>
                  )}
                />
              </Grid>

              <Grid xs={12} md={6}>
                <RHFTextField required name="contactPerson" label="Contact Person" />
              </Grid>

              <Grid xs={12} md={6}>
                <RHFTextField name="phoneNumber" label="Phone Number" type="tel" />
              </Grid>

              <Grid xs={12} md={6}>
                <RHFTextField
                  name="secondaryPhoneNumber"
                  label="Secondary Phone Number"
                  type="tel"
                />
              </Grid>

              <Grid xs={12} md={6}>
                <RHFTextField name="email" label="Email Address" type="email" />
              </Grid>

              <Divider
                sx={{
                  width: 1,
                  my: 2.5,
                  borderStyle: 'dashed',
                }}
              />

              <Grid mb={1} xs={12} width="full">
                <Typography mt={2} variant="subtitle2">
                  Supplies Information
                </Typography>
              </Grid>

              <Grid xs={12}>
                <Autocomplete
                  multiple
                  options={localItems}
                  getOptionLabel={(option) =>
                    typeof option === 'string' ? option : option.itemName
                  }
                  autoComplete
                  isOptionEqualToValue={(option, value) => option._id === value._id}
                  filterSelectedOptions
                  value={selectedItems}
                  onChange={(event: any, newValue: ICommonInventoryItem[] | null) => {
                    setSelectedItems(newValue ?? []);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Items"
                      fullWidth
                      placeholder="Search for items..."
                    />
                  )}
                  renderOption={(props, option) => (
                    <li key={option._id} {...props}>
                      <Typography
                        variant="body2"
                        sx={{ color: 'text.secondary', textTransform: 'capitalize' }}
                      >
                        {option.itemName}
                      </Typography>
                    </li>
                  )}
                />
              </Grid>
            </Grid>

            <Stack
              direction={{
                xs: 'column',
                sm: 'row',
              }}
              justifyContent={{
                xs: 'unset',
                sm: 'end',
              }}
              sx={{ mt: 3 }}
            >
              <Stack
                direction={{
                  xs: 'column',
                  sm: 'row',
                }}
                gap={2}
              >
                <Link
                  component={RouterLink}
                  href={paths.dashboard.suppliers.root}
                  sx={{
                    width: {
                      xs: 1,
                      sm: 'auto',
                    },
                  }}
                >
                  <Button
                    variant="outlined"
                    color="inherit"
                    sx={{
                      width: 1,
                    }}
                  >
                    Cancel
                  </Button>
                </Link>

                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  onClick={() => trigger()}
                >
                  {!currentSupplier ? 'Create Supplier' : 'Save Changes'}
                </LoadingButton>
              </Stack>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
