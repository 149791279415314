import { useEffect } from 'react';

import { Button } from '@mui/material';
import Container from '@mui/material/Container';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useResponsive } from 'src/hooks/use-responsive';

import { getBarPurchaseOrderById } from 'src/redux/slices/bar-purchase-order';

import ErrorContent from 'src/components/error-content';
import { useSettingsContext } from 'src/components/settings';
import { LeftIcon } from 'src/components/carousel/arrow-icons';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import { LoadingScreen } from '../../../components/loading-screen';
import BarPurchaseNewEditForm from '../bar-purchase-new-edit-form';
import { useAppDispatcher, useAppSelector } from '../../../redux/store';

type Props = {
  id: string;
};

export default function BarPurchaseOrderEditView({ id }: Props) {
  const { item, status, error } = useAppSelector((state) => state.barPurchaseOrders);

  const settings = useSettingsContext();
  const dispatch = useAppDispatcher();

  const smDown = useResponsive('down', 'sm');

  useEffect(() => {
    dispatch(getBarPurchaseOrderById({ itemID: id }));
  }, [dispatch, id]);

  if (status === 'loading' || status === 'idle') {
    return <LoadingScreen />;
  }

  if (status === 'failure') {
    return (
      <ErrorContent
        filled
        title="Failed to load bar purchase order details"
        description={error}
        action={
          <Button
            component={RouterLink}
            href={paths.dashboard.bar.purchase.root()}
            variant="contained"
            startIcon={<LeftIcon />}
          >
            Back to Bar Purchase Orders
          </Button>
        }
        sx={{
          py: 10,
        }}
      />
    );
  }

  if (status === 'success' && item) {
    return (
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Edit Bar Purchase Order"
          links={[
            ...(smDown
              ? []
              : [
                  {
                    name: 'Bar Purchase Orders',
                    href: paths.dashboard.bar.purchase.root(),
                  },
                ]),
            { name: 'Edit Bar Purchase Order' },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <BarPurchaseNewEditForm currentBarPurchaseItem={item} />
      </Container>
    );
  }

  return <span />;
}
